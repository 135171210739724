import { depositDetailState } from "@/recoil/deposit/atoms";
import { useQuery } from "react-query";
import { useSetRecoilState } from "recoil";
import { instance } from "../instance";

// 특정 날짜에 따른 가게의 입출금 내역을 조회합니다.
const get_deposit_detail = async (store_id: number, date?: string) => {
  const params = { date };

  const { data } = await instance({
    method: "get",
    url: `/v2/admin/deposits/store/${store_id}`,
    params: params,
  });

  return data;
};

export const useGetDepositDetail = (store_id: number, date?: string) => {
  const setDepositDetail = useSetRecoilState(depositDetailState);

  return useQuery(["DepositDetail", store_id, date], () => get_deposit_detail(store_id, date), {
    enabled: !!store_id,
    onSuccess: data => setDepositDetail(data.results),
  });
};
