/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

import { get_earlypay_detail, get_users_termination } from "src/apis/chart";

import { ChartCard } from "src/components/block";

import { getYearBetween } from "@/utils/betweenDate";
import { dataLabels, terminationUser, tooltips, yaxis } from "@/utils/chartOptions";
import { formatingDate } from "@/utils/date";
import { Option } from "src/types/graphType";
import Text from "@/components/elements/Text";

const YearDepositBox = () => {
  const [list, setList] = useState([]);
  const [earlypay, setEarlypay] = useState([]);
  const [user, setUser] = useState([]);
  const [sale, setSale] = useState([]);
  const [earlypayFee, setEarlypayFee] = useState([]);
  const [termination, setTermination] = useState([]);

  const getYearEarlypay = async () => {
    const { data } = await get_earlypay_detail(
      "year",
      formatingDate(new Date("2021-01-30")),
      formatingDate(new Date()),
    );
    const series: any = [];
    data.map((x: any) => series.push(x.date.split("-")[0]));
    setList(series);

    const earlypay: any = [{ name: "선정산금", data: [] }];
    const sale: any = [{ name: "서비스이용료", data: [] }];
    const user: any = [{ name: "유저", data: [] }];
    const earlypayFee: any = [{ name: "선정산이자", data: [] }];

    data.map((x: any) => earlypay[0].data.push(x.totalEarlypay));
    data.map((x: any) => user[0].data.push(x.totalEarlypayStore));
    data.map((x: any) => sale[0].data.push(x.totalServiceFee));
    data.map((x: any) => earlypayFee[0].data.push(x.totalEarlypayFee));

    setUser(user);
    setEarlypay(earlypay);
    setEarlypayFee(earlypayFee);
    setSale(sale);
  };

  const getYearTermination = async () => {
    const { data } = await get_users_termination(
      "year",
      formatingDate(new Date("2021-01-01")),
      formatingDate(new Date()),
    );

    const betwennDays = getYearBetween(formatingDate(new Date("2021-01-01")), formatingDate(new Date()));

    const termination: any = [
      { name: "일반해지", data: terminationUser(data, betwennDays, "일반 해지") },
      { name: "폐업해지", data: terminationUser(data, betwennDays, "폐업 해지") },
      { name: "null", data: terminationUser(data, betwennDays, "null") },
    ];
    setTermination(termination);
  };

  useEffect(() => {
    getYearEarlypay();
    getYearTermination();
  }, []);

  const maxNum: any = [];
  earlypay.map(x => maxNum.push(...x.data));

  const baseOptions: Option = {
    theme: { palette: "palette3" },
    chart: { type: "line" },
    colors: ["#0078d4", "#32d355", "#555"],
    xaxis: { categories: [...list] },
  };

  const earlypayOption = {
    ...baseOptions,
    dataLabels: { ...dataLabels.billion },
    yaxis: { ...yaxis.billion },
    tooltip: { ...tooltips.amount },
  };

  const userOption = {
    ...baseOptions,
    chart: { stacked: true },
    yaxis: { ...yaxis.count },
    tooltip: { ...tooltips.user },
  };

  const feeOption = {
    ...baseOptions,
    dataLabels: { ...dataLabels.million },
    yaxis: { ...yaxis.million },
    tooltip: { ...tooltips.amount },
  };

  return (
    <div style={{ width: "100%" }}>
      <ChartCard _dropdown={false} _title="연도별 정산지표" _date={false}>
        <Text size={18} bold>
          선정산금 (일일 선정산 + 일일 추가 선정산금)
        </Text>
        <ReactApexChart options={earlypayOption} series={earlypay} type="bar" height={"250px"} />
        <Text size={18} bold>
          선정산 시작 유저
        </Text>
        <ReactApexChart options={userOption} series={user} type="bar" height={"250px"} />
        <Text size={18} bold>
          선정산 해지 유저
        </Text>
        <ReactApexChart options={userOption} series={termination} type="bar" height={"250px"} />
        <Text size={18} bold>
          서비스 이용료
        </Text>
        <ReactApexChart options={feeOption} series={sale} type="bar" height={"250px"} />
        <Text size={18} bold>
          선정산 이자
        </Text>
        <ReactApexChart options={feeOption} series={earlypayFee} type="bar" height={"250px"} />
      </ChartCard>
    </div>
  );
};

export default YearDepositBox;
