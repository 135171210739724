/**
 * earlypay/summary api 의 경우 dashboard에 api 추적을 위해
 * page를 구분할수 있는 string값을 넣습니다.
 * "home" | "calendar" | "deposit"
 */
export interface DateRangeParams {
  startDate: string;
  endDate: string;
  storeId: number;
  page: "home" | "calendar" | "detail";
}

export const SALE_KEY = {
  card: "card",
  yogiyo: "yogiyo",
  baemin: "baemin",
  coupang: "coupang",
} as const;

export type SaleKeyType = keyof typeof SALE_KEY;

export const DEPOSIT_KEY = {
  ...SALE_KEY,
  earlypayServiceFee: "earlypayServiceFee",
} as const;

export type DepositKeyType = keyof typeof DEPOSIT_KEY;

export const EXTRA_KEY = {
  extraPayments: "extraPayments",
  overDeposits: "overDeposits",
} as const;

export type ExtraKeyType = keyof typeof EXTRA_KEY;

export const PLATFORM_NAME = {
  card: "카드",
  baemin: "배달의민족",
  yogiyo: "요기요",
  coupang: "쿠팡이츠",
} as const;
