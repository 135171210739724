import { themes } from "@carbon/themes";
import React from "react";
import { CustomText } from "./index.styled";

export interface TextProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  weight?: number;
  color?: string;
  size?: number;
  bold?: boolean;
  underline?: boolean;
}

export const Text = ({ children, bold, size = 14, color, weight = 500, style, underline, ...props }: TextProps) => {
  return (
    <CustomText
      bold={bold}
      size={size}
      weight={weight}
      color={color || themes.white.textPrimary}
      underline={underline}
      {...props}
      style={{ ...style }}
    >
      {children}
    </CustomText>
  );
};

export default Text;
