import styled from "styled-components";

export const LoginForm = styled.form`
  width: 100%;
  padding: 35px;
`;

export const LoginInput = styled.input`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 6px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;

  &:focus {
    border: 1px solid #0e5cff;
  }
`;

export const LoginText = styled.h2`
  line-height: 2em;
  font-size: 1em;
  color: #333;
`;

export const LoginButton = styled.button`
  width: 100%;
  background: #0e5cff;
  padding: 10px 15px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  margin: 14px auto 0 auto;
  font-weight: 500;
`;
