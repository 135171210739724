/* eslint-disable @typescript-eslint/no-explicit-any */
import Text from "@/components/elements/Text";
import React, { useEffect, useState } from "react";

import { get_users_statistics } from "src/apis/chart";

import { ChartCard, BarChart } from "src/components/block";
import styled from "styled-components";

const ItemChartBox = () => {
  const [api, setApi] = useState<any>({
    label: [],
    series: [],
  });
  const [api_2, setApi_2] = useState<any>({
    label: [],
    series: [],
  });

  const [dropDown, setDropDown] = useState([
    { key: "all", act: false },
    { key: "active", act: true },
  ]);

  const setChart = (data: any) => {
    const label = data.businessItem.map((x: any) => String(x.name));
    const series = data.businessItem.map((x: any) => Number(x.count));

    const label_2 = data.businessType.map((x: any) => String(x.name));
    const series_2 = data.businessType.map((x: any) => Number(x.count));

    setApi({
      label: [...label],
      series: [{ name: "count", data: [...series] }],
    });

    setApi_2({
      label: [...label_2],
      series: [{ name: "count", data: [...series_2] }],
    });
  };

  const getUser = async () => {
    const { data } = await get_users_statistics("active");
    setChart(data);
  };

  useEffect(() => {
    getUser();
  }, []);

  const chageChart = async (arr: { key: string; act: boolean }[]) => {
    setDropDown([...arr]);
    const trueKey = arr.filter(x => {
      if (x.act === true) return x;
    });
    const { data } = await get_users_statistics(trueKey[0].key);
    setChart(data);
  };

  return (
    <ChartCard _date={false} _dropDownArr={dropDown} _onClick={chageChart} _title={"업종 / 업태별 유저"}>
      <ItemBox>
        <div>
          <Text size={18} bold>
            업종별 유저
          </Text>
          <BarChart _labels={api.label} _series={api.series} _column={false} _height={"250px"} />
        </div>
        <div>
          <Text size={18} bold>
            업태별 유저
          </Text>
          <BarChart _labels={api_2.label} _series={api_2.series} _column={false} _height={"250px"} />
        </div>
      </ItemBox>
    </ChartCard>
  );
};

export default ItemChartBox;

const ItemBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  @media (max-width: 1100px) {
    display: block;
  }
`;
