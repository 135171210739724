import { IntegratedCardList, IntegratedDeliveryList } from "@/commons/constants";
import Text from "@/components/elements/Text";
import { DepositOverviewProps } from "@/types/depositType";
import { changeNumberToMoney } from "@/utils/changeNumberToMoney";
import { Table, TableBody, TableHead, TableHeader, TableRow } from "@carbon/react";
import { themes } from "@carbon/themes";
import React from "react";
import {
  CustomTableCell,
  DateLink,
  DepositOverviewTable,
  DepositTableTitle,
  TableWrapper,
  Wrapper,
} from "./index.styled";

export interface DepositOverviewListProps {
  items: DepositOverviewProps[];
  onClickDate?: (date: string) => void;
}

export const DepositOverviewList = ({ items, onClickDate }: DepositOverviewListProps) => {
  return (
    <Wrapper>
      {items.map((item, index) => {
        const expectedAmountList = Object.values(item)
          .filter(data => data.expectedAmount !== undefined)
          .map(data => changeNumberToMoney(data.expectedAmount));
        const actualAmountList = Object.values(item)
          .filter(data => data.actualAmount !== undefined)
          .map(data => changeNumberToMoney(data.actualAmount));
        const differenceList = Object.values(item)
          .filter(data => data.difference !== undefined)
          .map(data => changeNumberToMoney(data.difference));
        const totalAmountList = [
          "실회수액",
          changeNumberToMoney(item.totalWithdraw),
          "회수 내역 총액",
          changeNumberToMoney(item.totalDeposit),
          "정산 차액",
          changeNumberToMoney(item.totalDifference),
          "과입금",
          changeNumberToMoney(item.overDeposit),
          "미수금",
          changeNumberToMoney(item.underDeposit),
        ];

        return (
          <TableWrapper key={index}>
            {onClickDate && (
              <DateLink onClick={() => onClickDate(item.date)}>
                <DepositTableTitle underline size={14} bold>
                  {item.date}
                </DepositTableTitle>
              </DateLink>
            )}
            <div>
              <Table size="sm" aria-label="deposit table">
                <TableHead>
                  {/** 테이블 헤더 */}
                  <TableRow>
                    {["항목", ...IntegratedCardList, ...IntegratedDeliveryList, "기타"].map((label, index) => (
                      <TableHeader key={index}>{label}</TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/** 회수 예정액 Row */}
                  <TableRow>
                    {["회수예정액", ...expectedAmountList].map((value, index) => (
                      <CustomTableCell key={index}>
                        <Text bold={index === 0} color={themes.white.textSecondary}>
                          {value}
                        </Text>
                      </CustomTableCell>
                    ))}
                  </TableRow>

                  {/** 회수내역 Row */}
                  <TableRow>
                    {["회수내역", ...actualAmountList].map((value, index) => (
                      <CustomTableCell key={index}>
                        <Text bold={index === 0} color={themes.white.textSecondary}>
                          {value}
                        </Text>
                      </CustomTableCell>
                    ))}
                  </TableRow>

                  {/** 차액 Row */}
                  <TableRow>
                    {["차액", ...differenceList].map((value, index) => (
                      <CustomTableCell key={index}>
                        <Text bold={index === 0} color={themes.white.textSecondary}>
                          {value}
                        </Text>
                      </CustomTableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
              <DepositOverviewTable size="sm">
                <TableBody>
                  {/** 실회수액 | 최수 내역 총액 | 정산 차액 | 과입금 | 미수금 Row */}
                  <TableRow>
                    {totalAmountList.map((value, index) => {
                      const color = value.includes("-")
                        ? themes.white.textError
                        : value === "0"
                        ? themes.white.textSecondary
                        : themes.white.linkPrimary;

                      return (
                        <CustomTableCell key={index} isTitle={index % 2 === 0}>
                          <Text bold={index % 2 === 0} color={index === 5 && color}>
                            {value}
                          </Text>
                        </CustomTableCell>
                      );
                    })}
                  </TableRow>
                </TableBody>
              </DepositOverviewTable>
            </div>
          </TableWrapper>
        );
      })}
    </Wrapper>
  );
};

export default DepositOverviewList;
