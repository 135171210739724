import React, { useCallback, useState } from "react";
import { StoreProps } from "@/types/storeType";
import { useNavigateSearch } from "@/hooks/useNavigateSearch";
import { getDate } from "@/utils/getFormattedDate";

export const useSearchStore = () => {
  const { navigateSearch } = useNavigateSearch();
  const [isOpenSearchList, setIsOpenSearchList] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(-1);
  const today = getDate("now", "yyyy-mm-dd");

  /** 가게 검색을 클릭하였을 경우, 해당 검색 키워드 값을 params를 가진 페이지로 이동합니다. */
  const handleClickSearchStoreButton = useCallback(() => {
    navigateSearch(`/settlement`, { page: "1", page_size: "20", search: searchKeyword });
    setIsOpenSearchList(false);
  }, [searchKeyword]);

  /** 키보드 방향키를 통해 검색 추천 리스트에서 키워드를 선택할 수 있습니다. */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, storeList: StoreProps[]) => {
    if (e.key === "ArrowDown" && storeList) {
      if (e.nativeEvent.isComposing) return;
      e.preventDefault();
      setSelectedItemIndex(prevIndex => {
        return prevIndex < storeList.length - 1 ? prevIndex + 1 : -1;
      });
    } else if (e.key === "ArrowUp" && storeList) {
      if (e.nativeEvent.isComposing) return;
      e.preventDefault();
      setSelectedItemIndex(prevIndex => (prevIndex > -1 ? prevIndex - 1 : storeList.length - 1));
    } else if (e.key === "Enter") {
      if (selectedItemIndex === -1) {
        // 해당 검색 키워드 값을 params를 가진 페이지로 이동합니다.
        handleClickSearchStoreButton();
      } else {
        // 선택한 가게의 정산조정 상세 페이지로 이동
        const selectedList = storeList && storeList[selectedItemIndex];
        setSearchKeyword(selectedList.title);
        handleGoToDetail(selectedList.id, selectedList.title);
        setIsOpenSearchList(false);
      }
    }
  };

  /** 가게 검색 input 이 focus 될 때 selectedItemIndex 값을 초기화 합니다. */
  const handleFocus = () => {
    setSelectedItemIndex(-1);
  };

  /** 가게 검색 input 이 focus out 될 때 가게 추천 리스트를 닫습니다. */
  const handleBlur = () => {
    //setIsOpenSearchList(false);
  };

  /** 가게 검색 input 에서 onChange 호출 */
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedItemIndex(-1);
      setSearchKeyword(e.target.value);
      setIsOpenSearchList(searchKeyword?.length !== 0);
    },
    [searchKeyword],
  );

  /** 정산조정 상세 페이지로 이동 */
  const handleGoToDetail = (id: number, store: string) => {
    navigateSearch(`/settlement/detail/${id}`, { search: store, start_date: today, end_date: today });
    setIsOpenSearchList(false);
  };

  return {
    selectedItemIndex,
    searchKeyword,
    setSearchKeyword,
    handleFocus,
    handleBlur,
    handleKeyDown,
    handleChange,
    handleGoToDetail,
    handleClickSearchStoreButton,
    isOpenSearchList,
  };
};
