import { Button, Stack } from "@carbon/react";
import styled from "styled-components";

export const Wrapper = styled.div`
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const AutoWidthButton = styled(Button).attrs({
  kind: "tertiary",
  iconDescription: "auto-width",
})`
  padding: 7px 16px;
`;

export const FullWidthButton = styled(Button).attrs({
  kind: "tertiary",
  iconDescription: "full-width",
})`
  width: 100%;
  min-width: 100%;
  padding: 7px 16px;
  align-items: center;
  justify-content: flex-start;
`;

export const RecoveryWrapper = styled(Stack)`
  padding: 32px;
`;

export const ColumnStack = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MismatchedStoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 15px;
  gap: 24px;
  height: 100%;
  min-height: 100%;
  justify-content: space-between;
`;

export const MismatchedStoreTitleStack = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BottomStack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
