import { themes } from "@carbon/themes";
import styled from "styled-components";

export const CustomText = styled.p`
  width: fit-content;
  font-weight: ${({ weight, bold }) => (bold ? 600 : weight)};
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  font-size: ${({ size }) => size}px;
  color: ${({ color, underline }) => (underline ? themes.white.linkPrimary : color)};
  letter-spacing: 0.16px;
  line-height: normal;
  margin: 0;
  padding: 0;
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
`;
