import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Outlet } from "react-router-dom";

import SettlementSideNav from "@/components/features/settlement/SettlementSideNav";
import { getCookie } from "@/utils/cookie";
import { saveToken } from "@/utils/token";
import { SettlementContentWrapper, SettlementLayoutWrapper, SettlementSideNavWrapper } from "./index.styled";

const SettlementLayout = () => {
  const navigate = useNavigate();

  const userToken = getCookie("ep_tk");

  useEffect(() => {
    if (!userToken) {
      return navigate("/login"); // useNavigate hook 은 useEffect안에서만 마운트 실행을 할수있음
    }
  }, [userToken]);

  if (userToken) {
    saveToken(userToken);
  } else {
    return null;
  }

  return (
    <SettlementLayoutWrapper>
      {/** 얼리페이 헤더 */}
      {/* <HeaderWrapper aria-label="Calculate header">
        <HeaderName href="/" prefix="">
          <EarlypaySymbol width={23} height={16} />
          <EarlypayWordmark width={87} height={18} />
        </HeaderName>

        <HeaderGlobalBar>
          <HeaderButton size="md" kind="ghost" renderIcon={PasswordIcon}>
            비밀번호 변경
          </HeaderButton>
          <HeaderButton size="md" kind="ghost" renderIcon={LogoutIcon}>
            로그아웃
          </HeaderButton>
        </HeaderGlobalBar>
      </HeaderWrapper> */}

      {/** 정산 사이드 바 */}
      <SettlementSideNavWrapper aria-label="Calculate side bar" expanded={true} isFixedNav isChildOfHeader={false}>
        <SettlementSideNav />
      </SettlementSideNavWrapper>

      {/** 콘텐츠 */}
      <SettlementContentWrapper>
        <Outlet />
      </SettlementContentWrapper>
    </SettlementLayoutWrapper>
  );
};

export default SettlementLayout;
