import React from "react";
// chart
import ReactApexChart from "react-apexcharts";
// type
import { PieSeries, Option, Height, PieLabels } from "src/types/graphType";

interface PieGraphInterface {
  _series: PieSeries;
  _labels: PieLabels;
  _height?: Height;
  _chartType?: number;
}

const PieChart = ({ _series = [1], _labels = ["default"], _height = "450px", _chartType = 1 }: PieGraphInterface) => {
  const options: Option = {
    theme: { palette: `palette${_chartType}` },
    chart: {
      type: "donut",
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "14px",
      fontWeight: 400,
    },
    colors: [
      "#0078d4",
      "#66DA26",
      "#546E7A",
      "#E91E63",
      "#FF9800",
      "#0078d4",
      "#66DA26",
      "#546E7A",
      "#E91E63",
      "#FF9800",
      "#0078d4",
      "#66DA26",
      "#546E7A",
      "#E91E63",
      "#FF9800",
      "#0078d4",
      "#66DA26",
      "#546E7A",
      "#E91E63",
    ],
    labels: [..._labels],
    plotOptions: {
      pie: {
        donut: {
          size: "70px",
          labels: {
            show: true,
            name: { show: true },
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.round(Number(val)) + "%";
      },
      dropShadow: { enabled: false },
      style: {
        colors: ["#fff"],
      },
    },
    tooltip: { fillSeriesColor: false },
  };

  const series = [..._series];

  return (
    <>
      <ReactApexChart options={options} series={series} type="donut" height={_height} />
    </>
  );
};

export default PieChart;
