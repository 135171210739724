import { getCookie, setCookie } from "@/utils/cookie";
import { instance } from "src/apis/instance";

const saveToken = (token: string) => {
  setCookie("ep_tk", token, 365);

  Object.assign(instance.defaults, {
    headers: { authorization: `token ${token}` },
  });
};

const deleteToken = (name: string) => {
  if (getCookie(name)) {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  Object.assign(instance.defaults, {
    headers: { authorization: `` },
  });
};

export { deleteToken, saveToken };
