import { Table, TableCell, TableContainer, TableHeader } from "@carbon/react";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
`;

export const TableWrapper = styled(TableContainer)`
  height: 100%;

  .cds--data-table_inner-container {
    height: 100%;
    background-color: white;
  }

  .cds--data-table-content {
    height: 100%;
  }
`;

export const DepositTable = styled(Table)`
  overflow-x: hidden;
  height: 100%;
  min-height: 100%;

  > thead {
    > th {
      &:last-child {
        justify-content: end;
        padding-right: 24px;
      }
    }
  }

  > tbody {
    > tr {
      > td {
        padding-block-end: 0px;
        padding-block-start: 0px !important;
        display: flex;
        align-items: center;

        &:last-child {
          justify-content: end;
        }
      }
    }
  }
`;

export const EditTableCell = styled(TableCell)`
  padding: 0 !important;
  width: 20% !important;
  min-width: 20% !important;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  table-layout: fixed;
  width: 100%;
`;

export const CustomTableHeader = styled(TableHeader)`
  width: 20%;
  min-width: 20%;
`;

export const CustomTableCell = styled(TableCell)`
  width: 20% !important;
  min-width: 20% !important;
`;
