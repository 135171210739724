import { instance } from "./instance";

import type {
  AllStoreInterface,
  BizppurioApiInterface,
  CfaSaleInterface,
  EarlypayInterface,
  ExtendStoreInterface,
  GetSaleInterface,
  GetUserInterface,
  PlatformSaleInterface,
} from "src/types/adminType";
import type {
  AdsType,
  DepositInterface,
  ExtraPaymentType,
  OverDepositType,
  UnuderDepositType,
} from "src/types/depositType";
import type { SalesInterface } from "src/types/saleType";

export const get_cs_all_stores = async (req: GetUserInterface): Promise<AllStoreInterface> => {
  const params: GetUserInterface = { ...req };

  const { data } = await instance({
    method: "get",
    url: `/v1/cs/stores/`,
    params: params,
  });

  return data;
};

export const get_cs_store_earlypay = async (req: GetUserInterface, stroeId: number): Promise<EarlypayInterface> => {
  const params: GetUserInterface = { ...req };

  const { data } = await instance({
    method: "get",
    url: `/v1/cs/stores/${stroeId}/earlypays/`,
    params: params,
  });

  return data;
};

export const get_store_detail = async (req: GetUserInterface, stroeId: number): Promise<ExtendStoreInterface> => {
  const params: GetUserInterface = { ...req };

  const { data } = await instance({
    method: "get",
    url: `/v1/cs/stores/${stroeId}/`,
    params: params,
  });

  return data;
};

export const get_store_bizppurio = async (req: GetUserInterface, stroeId: number): Promise<BizppurioApiInterface> => {
  const params: GetUserInterface = { ...req };

  const { data } = await instance({
    method: "get",
    url: `/v1/cs/stores/${stroeId}/bizppurio/`,
    params: params,
  });

  return data;
};

export const get_all_sales = async (req: GetSaleInterface, stroeId: number): Promise<PlatformSaleInterface[]> => {
  const params: GetSaleInterface = { ordering: "earlypay", ...req };

  const { data } = await instance({
    method: "get",
    url: `/v1/cs/stores/${stroeId}/sales/`,
    params: params,
  });

  return data;
};

export const get_sale_summary = async (req: GetSaleInterface, stroeId: number): Promise<SalesInterface> => {
  const params: GetSaleInterface = { ...req };

  const { data } = await instance({
    method: "get",
    url: `/v1/cs/stores/${stroeId}/sales/summary/`,
    params: params,
  });

  return data;
};

export const get_earlypay_summary = async (date: string, stroeId: number): Promise<DepositInterface> => {
  const params = { date };

  const { data } = await instance({
    method: "get",
    url: `/v1/cs/stores/${stroeId}/earlypays/summary/`,
    params: params,
  });

  return data;
};

export const get_extra_payments = async (date: string, stroeId: number): Promise<ExtraPaymentType[]> => {
  const params = { date };

  const { data } = await instance({
    method: "get",
    url: `/v1/cs/stores/${stroeId}/extra-payments/`,
    params: params,
  });
  return data;
};

export const get_over_deposits = async (date: string, stroeId: number): Promise<OverDepositType[]> => {
  const params = { date };

  const { data } = await instance({
    method: "get",
    url: `/v1/cs/stores/${stroeId}/over-deposits/`,
    params: params,
  });

  return data;
};

export const get_under_deposits = async (earlypayId: string): Promise<UnuderDepositType[]> => {
  const { data } = await instance({
    method: "get",
    url: `/v1/cs/earlypays/${earlypayId}/under-deposits/`,
  });

  return data;
};

export const get_ads = async (earlypayId: string): Promise<AdsType[]> => {
  const { data } = await instance({
    method: "get",
    url: `/v1/cs/earlypays/${earlypayId}/ads/`,
  });

  return data;
};

export const get_cfa_sales = async (stroeId: number, date: string): Promise<CfaSaleInterface[]> => {
  const params = { date };

  const { data } = await instance({
    method: "get",
    url: `/v1/cs/stores/${stroeId}/cfa/sales/`,
    params: params,
  });

  return data;
};

export const get_export_url = async (stroeId: string, startDate: string, endDate: string): Promise<{ url: string }> => {
  const params = { start_date: startDate, end_date: endDate };

  const { data } = await instance({
    method: "get",
    url: `/v1/cs/stores/${stroeId}/earlypays/export/`,
    params: params,
  });

  return data;
};

export const get_yogiyo_export_url = async (
  stroeId: string,
  startDate: string,
  endDate: string,
): Promise<{ url: string }> => {
  const params = { start_date: startDate, end_date: endDate };

  const { data } = await instance({
    method: "get",
    url: `/v1/cs/stores/${stroeId}/sales/yogiyo/export/`,
    params: params,
  });

  return data;
};
