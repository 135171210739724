export const getDatesBetween = (startDay: string, endDay: string) => {
  const start: Date = new Date(startDay);
  const end: Date = new Date(endDay);

  const dates: Date[] = [];

  while (start <= end) {
    dates.push(new Date(start));
    start.setDate(start.getDate() + 1);
  }

  return dates.map(date => {
    const year = date.getFullYear();
    const month =
      String(date.getMonth() + 1).length < 2 ? `0${String(date.getMonth() + 1)}` : String(date.getMonth() + 1);
    const day = String(date.getDate()).length < 2 ? `0${String(date.getDate())}` : String(date.getDate());

    return `${year}-${month}-${day}`;
  });
};

export const getMonthBetween = (startDay: string, endDay: string) => {
  const start: Date = new Date(startDay);
  const end: Date = new Date(endDay);

  const dates: Date[] = [];

  while (start <= end) {
    dates.push(new Date(start));
    start.setMonth(start.getMonth() + 1);
  }

  return dates.map(date => {
    const year = date.getFullYear();
    const month =
      String(date.getMonth() + 1).length < 2 ? `0${String(date.getMonth() + 1)}` : String(date.getMonth() + 1);
    const day = String(date.getDate()).length < 2 ? `0${String(date.getDate())}` : String(date.getDate());

    return `${year}-${month}-${day}`;
  });
};

export const getYearBetween = (startDay: string, endDay: string) => {
  const start: Date = new Date(startDay);
  const end: Date = new Date(endDay);

  const dates: Date[] = [];

  while (start <= end) {
    dates.push(new Date(start));
    start.setFullYear(start.getFullYear() + 1);
  }

  return dates.map(date => {
    const year = date.getFullYear();
    const month =
      String(date.getMonth() + 1).length < 2 ? `0${String(date.getMonth() + 1)}` : String(date.getMonth() + 1);
    const day = String(date.getDate()).length < 2 ? `0${String(date.getDate())}` : String(date.getDate());

    return `${year}-${month}-${day}`;
  });
};
