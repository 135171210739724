/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";

import { get_earlypay, get_earlypay_detail } from "src/apis/chart";

import { ChartCard } from "src/components/block";
import styled from "styled-components";

import { formatingDate } from "@/utils/date";
import theme from "src/styles/theme";

const EarlypayBox = () => {
  type Earlypay = {
    date: string;
    totalEarlypay: number;
    totlaEarlypayFee: number;
    totalServiceFee: number;
    totalUnderDeposit: number;
    totalOverDeposit: number;
    totalActiveStore: number;
    totalEarlypayStore: number;
  };

  const [ealrypay, setEarlypay] = useState<Earlypay>();
  const [ealrypayDay, setEarlypayDay] = useState<any>([]);

  const getAllEalrypay = async () => {
    const { data } = await get_earlypay();
    setEarlypay({ ...data });
  };

  const getToday = async () => {
    const { data } = await get_earlypay_detail("day", formatingDate(new Date()), formatingDate(new Date()));
    setEarlypayDay(data);
  };

  useEffect(() => {
    getAllEalrypay();
    getToday();
  }, []);

  return (
    <DepositWrap>
      <ChartCard _date={false} _dropdown={false} _title="총 누적 선정산금">
        <DepositBox>
          {ealrypay?.totalEarlypay?.toLocaleString()}
          <span
            style={{
              fontSize: "14px",
              color: theme.color.blue_00,
            }}
          >
            (+
            {ealrypayDay[0]?.totalEarlypay?.toLocaleString()})
          </span>
          <span style={{ fontSize: "16px" }}> 원</span>
        </DepositBox>
      </ChartCard>
      <ChartCard _date={false} _dropdown={false} _title="총 누적 서비스이용료">
        <DepositBox>
          {ealrypay?.totalServiceFee.toLocaleString()}
          <span
            style={{
              fontSize: "14px",
              color: theme.color.blue_00,
            }}
          >
            (+
            {ealrypayDay[0]?.totalServiceFee?.toLocaleString()})
          </span>
          <span style={{ fontSize: "16px" }}> 원</span>
        </DepositBox>
      </ChartCard>
      <ChartCard _date={false} _dropdown={false} _title="총 누적 선정산이자">
        <DepositBox>
          {ealrypay?.totlaEarlypayFee.toLocaleString()}
          <span
            style={{
              fontSize: "14px",
              color: theme.color.blue_00,
            }}
          >
            (+
            {ealrypayDay[0]?.totalEarlypayFee?.toLocaleString()})
          </span>
          <span style={{ fontSize: "16px" }}> 원</span>
        </DepositBox>
      </ChartCard>
      <ChartCard _date={false} _dropdown={false} _title="총 승인가게 / 총 선정산가게">
        <DepositBox>
          {ealrypay?.totalActiveStore} <span style={{ fontSize: "16px" }}> 개</span> / {ealrypay?.totalEarlypayStore}
          <span
            style={{
              fontSize: "14px",
              color: theme.color.blue_00,
            }}
          >
            (+
            {ealrypayDay[0]?.totalEarlypayStore?.toLocaleString()})
          </span>
          <span style={{ fontSize: "16px" }}> 개</span>
          <span
            style={{
              fontSize: "14px",
              color: theme.color.blue_00,
            }}
          ></span>
        </DepositBox>
      </ChartCard>
    </DepositWrap>
  );
};

export default EarlypayBox;

const DepositWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 12px;
  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;

const DepositBox = styled.div`
  width: 100%;

  font-size: 22px;
  color: #555;
  font-weight: 500;
  text-align: end;
`;
