import Text from "@/components/elements/Text";
import { getTime } from "@/utils/getTime";
import { PaginationNav, Stack } from "@carbon/react";
import React, { useEffect, useState } from "react";
import {
  AutoWidthButton,
  FullWidthButton,
  MismatchedStoreTitleStack,
  MismatchedStoreWrapper,
  RecoveryWrapper,
  Wrapper,
} from "./index.styled";

export const SettlementSideNav = () => {
  const [isDisabledGetMoney, setIsDisabledGetMoney] = useState(true);
  const [isSelectedGetMoney, setIsSelectedGetMoney] = useState(false);

  useEffect(() => {
    const hour = getTime();
    setIsDisabledGetMoney(false);
    if (hour > 17 && hour < 24) {
      setIsDisabledGetMoney(false);
    }
  }, []);

  const handleClickGetMoney = () => {
    setIsSelectedGetMoney(true);
  };

  return (
    <Wrapper>
      {isSelectedGetMoney ? (
        <MismatchedStoreWrapper>
          <Stack gap="24px">
            <MismatchedStoreTitleStack>
              <Text size={20} bold>
                실회수금 불일치 가게
              </Text>
              <AutoWidthButton size="sm">새로고침</AutoWidthButton>
            </MismatchedStoreTitleStack>

            {/* <MismatchedStoreAccordion items={mockMismatchedStoreList.results} /> */}
          </Stack>

          <Stack gap="8px">
            <PaginationNav size="sm" itemsShown={5} totalItems={25} />

            <Stack gap="8px">
              <FullWidthButton size="lg">실제 출금 내역 검토하기</FullWidthButton>
              <FullWidthButton size="lg">과수금 / 미수금 계산하기</FullWidthButton>
            </Stack>
          </Stack>
        </MismatchedStoreWrapper>
      ) : (
        <RecoveryWrapper gap="16px">
          <Text size={20} bold>
            회수 내역이 없습니다.
          </Text>
          <AutoWidthButton size="sm" disabled={isDisabledGetMoney} onClick={handleClickGetMoney}>
            회수내역 가져오기
          </AutoWidthButton>
        </RecoveryWrapper>
      )}
    </Wrapper>
  );
};

export default SettlementSideNav;
