import React from "react";

import { DefaultGridStyle, RowGridStyle, ModalGridStyle, TopGridStyle, BottomGridStyle } from "./index.styled";

interface GridPropsType {
  _relative?: boolean;
  _justify?: boolean;
  _align?: boolean;
  _hidden?: boolean;
  _scroll?: boolean;
  _onClick?: () => void;
  className?: string;
  _style?: object;
  _row?: boolean;
  _modal?: boolean;
  _top?: boolean;
  _bottom?: boolean;
  children?: React.ReactNode;
}

const Grid = ({
  _relative,
  _justify,
  _align,
  _hidden,
  _scroll,
  _onClick,
  className,
  _style,
  _row,
  _modal,
  _top,
  _bottom,
  children,
}: GridPropsType) => {
  const styles = { _relative, _justify, _align, _hidden, _scroll };

  if (_row) {
    return (
      <RowGridStyle style={{ ..._style }} {...styles} className={className} onClick={_onClick}>
        {children}
      </RowGridStyle>
    );
  }
  if (_modal) {
    return (
      <ModalGridStyle style={{ ..._style }} {...styles} className={className} onClick={_onClick}>
        {children}
      </ModalGridStyle>
    );
  }
  if (_top) {
    return (
      <TopGridStyle style={{ ..._style }} {...styles} className={className} onClick={_onClick}>
        {children}
      </TopGridStyle>
    );
  }
  if (_bottom) {
    return (
      <BottomGridStyle style={{ ..._style }} {...styles} className={className} onClick={_onClick}>
        {children}
      </BottomGridStyle>
    );
  }

  return (
    <DefaultGridStyle style={{ ..._style }} {...styles} className={className} onClick={_onClick}>
      {children}
    </DefaultGridStyle>
  );
};

export default Grid;
