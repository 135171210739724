import { Text } from "@/components/atoms";
import styled from "styled-components";

export const SearchBarTitle = styled.h3`
  margin: 0 0 20px;
  font-weight: 300;
  font-size: 20px;
  color: #666;
`;

export const SearchBarWrapper = styled.form`
  width: 100%;
  border: 1px solid #eee;
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 15px 20px;

  display: grid;
  grid-template-columns: 300px 50px 1fr;
  gap: 10px;
  align-items: center;

  @media (max-width: 425px) {
    grid-template-columns: 150px 50px 1fr;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  border: 2px solid #ddd;

  &:focus {
    border-radius: 5px;
    border: 2px solid #0078d4;
  }
`;

export const SearchButton = styled.button`
  width: auto;
  padding: 0;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 -15px 20px -10px rgba(0, 0, 0, 0.15) inset;
  cursor: pointer;
  color: #1f1f1f;
  padding: 5px;
`;

export const SearchCountText = styled(Text)`
  font-size: 12px;
  color: #999;
  word-break: keep-all;

  b {
    color: #0078d4;
  }
`;
