import styled from "styled-components";

export const LoginPage = styled.div`
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  max-width: 28em;
  min-width: 10em;
  margin: 100px auto;
  height: auto;
`;

export const LoginTitle = styled.div`
  width: 100%;
  text-align: center;
  padding: 15px;
  background: #0e5cff;
  color: #fff;
  font-weight: 600;
  font-size: 25px;
`;
