import React from "react";
import { useForm } from "react-hook-form";

import { SearchBarTitle, SearchBarWrapper, SearchInput, SearchButton, SearchCountText } from "./index.styled";

interface InputValueInterface {
  searchKeyword: string;
}

interface SearchBarInterface {
  _onClick: (keyword: string) => void;
  _title: string;
  _searchCount?: number;
  _placeholder?: string;
}

const SearchBar = ({
  _onClick,
  _title = "제목을 입력해주세요",
  _searchCount = 0,
  _placeholder = "검색어 입력",
}: SearchBarInterface) => {
  const { register, handleSubmit } = useForm<InputValueInterface>();

  const submitHandler = ({ searchKeyword }: InputValueInterface): void => {
    _onClick(searchKeyword);
  };

  return (
    <>
      <SearchBarTitle>{_title}</SearchBarTitle>
      <SearchBarWrapper onSubmit={handleSubmit(submitHandler)}>
        <SearchInput placeholder={_placeholder} {...register("searchKeyword")} />
        <SearchButton>검색</SearchButton>
        <SearchCountText>
          <b>{_searchCount}</b>건 검색됨
        </SearchCountText>
      </SearchBarWrapper>
    </>
  );
};

export default SearchBar;
