import React from "react";

import { DefaultButtonStyle, FullButtonStyle, SmallButtonStyle } from "./index.styled";

interface ButtonPropsType {
  _onClick?: () => void;
  _disabled?: boolean;
  _style?: object;
  _full?: boolean;
  _small?: boolean;
  _noRipple?: boolean;
  _id?: string;
  children?: React.ReactNode | string | [];
  className?: string;
}

const Button = ({
  _onClick,
  _disabled,
  _style,
  _full,
  _small,
  _noRipple,
  _id,
  children,
  className,
}: ButtonPropsType) => {
  // 버튼 클릭 이벤트 (물결무늬 + 클릭이벤트)
  const createRipple = async (event: React.MouseEvent<HTMLDivElement>) => {
    // _noRipple이 아닐경우 물결효과를 만듭니다.
    if (!_noRipple) {
      const button = event?.currentTarget;
      // fixed의 경우 제대로된 위치값을 불러오지 못하기 때문에 getBoundingClientRect 사용합니다.
      const rect = button.getBoundingClientRect();
      const circle = document.createElement("span");
      const diameter = Math.max(button.clientWidth, button.clientHeight);
      const radius = diameter / 2;
      circle.style.width = circle.style.height = `${diameter}px`;
      circle.style.left = `${event?.clientX - rect.left - radius}px`;
      circle.style.top = `${event?.clientY - rect.top - radius}px`;

      circle.classList.add("ripple");

      button.appendChild(circle);

      setTimeout(() => {
        button.removeChild(circle);
      }, 500);
    }

    // props 클릭 이벤트
    _onClick && _onClick();
  };

  if (_full) {
    return (
      <FullButtonStyle className={className} id={_id} onClick={createRipple} disabled={_disabled} style={{ ..._style }}>
        {children}
      </FullButtonStyle>
    );
  }

  if (_small) {
    return (
      <SmallButtonStyle
        className={className}
        id={_id}
        onClick={createRipple}
        disabled={_disabled}
        style={{ ..._style }}
      >
        {children}
      </SmallButtonStyle>
    );
  }

  return (
    <DefaultButtonStyle
      className={className}
      id={_id}
      onClick={createRipple}
      disabled={_disabled}
      style={{ ..._style }}
    >
      {children}
    </DefaultButtonStyle>
  );
};

export default Button;
