import { useDeleteDepositDetail } from "@/apis/deposit/deleteDepositDetail";
import Text from "@/components/elements/Text";
import { storeDetailState } from "@/recoil/stores/atoms";
import { DepositDetailProps } from "@/types/depositType";
import { Modal } from "@carbon/react";
import React, { useCallback } from "react";
import { useRecoilValue } from "recoil";

export interface DeleteDepositModalProps {
  isVisible: boolean;
  onClose: () => void;
  selectedValues: DepositDetailProps;
}

export const DeleteDepositModal: React.FC<DeleteDepositModalProps> = ({ isVisible, onClose, selectedValues }) => {
  const storeData = useRecoilValue(storeDetailState);
  const mutation = useDeleteDepositDetail(storeData.id);

  /** 입출금 내역 삭제 함수 */
  const handleDeleteDepositDetail = useCallback(() => {
    mutation.mutate(selectedValues.id);
    onClose();
  }, [mutation]);

  return (
    <Modal
      open={isVisible}
      onRequestClose={onClose}
      onClose={onClose}
      onRequestSubmit={handleDeleteDepositDetail}
      danger
      modalHeading="정말로 삭제 하시겠습니까?"
      primaryButtonText="삭제"
      secondaryButtonText="취소"
    >
      <Text>삭제되면 다시 되돌릴 수 없습니다.</Text>
    </Modal>
  );
};
