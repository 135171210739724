/* eslint-disable @typescript-eslint/no-explicit-any */
import { FlexBox } from "@/components/atoms";
import React from "react";
import { DropDwon } from "src/components/block";
// component(styled)
import DateBox from "../DateBox";
import { CardBox, ChartBox, TitleBox } from "./index.styled";
import Text from "@/components/elements/Text";

interface ChartCardInterface {
  _title: string;
  children: React.ReactNode;
  _onClick?: (arr: { key: string; act: boolean }[]) => void;
  _dropDownArr?: { key: string; act: boolean; name?: string }[];
  _dropdown?: boolean;
  _date?: boolean;
  _dateObj?: { start: Date; end: Date };
  _setDateObj?: (date: { start: Date; end: Date }) => void;
  _month?: boolean;
}

const ChartCard = ({
  _title = "title",
  children = <div>body</div>,
  _onClick = () => {},
  _dropDownArr = [{ key: "default", act: true }],
  _dropdown = true,
  _date = true,
  _dateObj = { start: new Date(), end: new Date() },
  _setDateObj,
  _month = false,
}: ChartCardInterface) => {
  return (
    <CardBox>
      <TitleBox>
        <Text size={14}>{_title}</Text>
        <FlexBox _row _style={{ width: "auto", gap: "5px" }} _align>
          {_date && <DateBox _dateObj={_dateObj} _setDateObj={_setDateObj} _month={_month} />}
          {_dropdown && <DropDwon _onClick={_onClick} _dropDownArr={_dropDownArr} />}
        </FlexBox>
      </TitleBox>
      <ChartBox>{children}</ChartBox>
    </CardBox>
  );
};

export default ChartCard;
