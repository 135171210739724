import styled from "styled-components";

export const ListTableWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const TableHeader = styled.div`
  width: 100%;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  background-color: #f9f9f9;
  padding: 5px 10px;
  display: grid;
  grid-template-columns: ${(props: { columnsLenght: number }) => "80px " + "1fr ".repeat(props.columnsLenght - 1)};
  font-size: 14px;
  gap: 10px;

  @media (max-width: 425px) {
    grid-template-columns: 50px 1fr 1fr 1fr;
    font-size: 13px;

    div {
      display: none;

      &:nth-child(-n + 4) {
        display: block;
      }
    }
  }
`;

export const List = styled.div`
  width: 100%;
  border-bottom: 1px solid #eee;
  padding: 8px 10px;
  display: grid;
  grid-template-columns: ${(props: { columnsLenght: number }) => "80px " + "1fr ".repeat(props.columnsLenght - 1)};
  font-size: 14px;
  background-color: #fcfcfc;
  gap: 10px;

  &:nth-child(even) {
    background-color: #fff;
  }

  div {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;

    &:nth-child(1) {
      color: #208dc4;
      cursor: pointer;
      font-weight: 500;
    }
  }

  @media (max-width: 425px) {
    grid-template-columns: 50px 1fr 1fr 1fr;
    font-size: 13px;

    div {
      display: none;

      &:nth-child(-n + 4) {
        display: block;
      }
    }
  }
`;
