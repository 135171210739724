const formattedDate = (value?: string | Date | number, format?: string) => {
  let dateObject = new Date();

  if (value && value !== "now") {
    dateObject = new Date(value);
  }

  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 +1을 해줘야 합니다.
  const day = String(dateObject.getDate()).padStart(2, "0");
  const hours = String(dateObject.getHours()).padStart(2, "0");
  const minutes = String(dateObject.getMinutes()).padStart(2, "0");

  if (format === "yyyy-mm-dd") {
    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${hours}:${minutes}`;
    return [formattedDate, formattedTime];
  } else {
    const formattedDate = `${year}.${month}.${day}`;
    const formattedTime = `${hours}:${minutes}`;

    return [formattedDate, formattedTime];
  }
};

export const getDate = (value?: string | Date | number, format?: string) => {
  const date = formattedDate(value, format)[0];

  return date;
};

export const getDateTime = (value?: string | Date | number, format?: string) => {
  const dateTime = `${formattedDate(value, format)[0]} ${formattedDate(value)[1]}`;

  return dateTime;
};

export const getTime = (value?: string | Date | number, format?: string) => {
  const time = formattedDate(value, format)[1];

  return time;
};
