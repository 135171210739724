import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

import { get_ads, get_extra_payments, get_over_deposits, get_under_deposits } from "src/apis/admin";

import { CircleText, PriceText, SubPriceText, SubTitleText, TabGrid, TitleText } from "./index.styled";

import type { DetailPropsInterface } from "src/pages/Admin/Detail";
import type { AdsType, ExtraPaymentType, OverDepositType, UnuderDepositType } from "src/types/depositType";

interface AnotherEarlypayInterface extends DetailPropsInterface {
  _calculateDeposit: number;
}

const AnotherEarlypay = ({ _calculateDeposit, _date, _storeId }: AnotherEarlypayInterface) => {
  const earlypayId = useLocation().search.split("&")[0].split("=")[1];
  const [baeminAds, setBaeminAds] = useState<AdsType[]>([]);
  const [yogiyoAds, setYogiyoAds] = useState<AdsType[]>([]);
  const [extra, setExtra] = useState<ExtraPaymentType[]>([]);
  const [over, setOver] = useState<OverDepositType[]>([]);
  const [under, setUnder] = useState<UnuderDepositType[]>([]);
  const totalExtra = extra.map(pay => pay?.amount).reduce((prev, cur) => prev + cur, 0);
  const repaymentUnderDeposits = under.map(
    deposit =>
      deposit.repayments.filter(under => {
        if (under.created.split("T")[0] === _date) return under;
      })[0].amount,
  );
  const totalUnderDeposit = repaymentUnderDeposits.reduce((prev, cur) => prev + cur, 0);
  const totalEarlypay = _calculateDeposit - totalUnderDeposit;

  const getUnder = async () => {
    const under = await get_under_deposits(earlypayId);
    setUnder(under);
  };

  const getAds = async () => {
    const ads = await get_ads(earlypayId);

    const baeminList = ads.filter(ad => {
      if (ad.platform === "배달의민족") return ad;
    });

    const yogiyoList = ads.filter(ad => {
      if (ad.platform === "요기요") return ad;
    });
    setBaeminAds(baeminList);
    setYogiyoAds(yogiyoList);
  };

  const getExtraPayments = async () => {
    const extra = await get_extra_payments(_date, _storeId);
    setExtra(extra);
  };

  const getOverDeposit = async () => {
    const over = await get_over_deposits(_date, _storeId);
    setOver(over);
  };

  useEffect(() => {
    getAds();
    getExtraPayments();
    getOverDeposit();
    getUnder();
  }, []);

  return (
    <div>
      <TabGrid>
        <TitleText>미수금</TitleText>
        <PriceText>-{totalUnderDeposit.toLocaleString()}원</PriceText>
      </TabGrid>
      <div>
        {under.map((deposit, index) => {
          const repayment = deposit.repayments.filter(under => {
            if (under.created.split("T")[0] === _date) return under;
          })[0].amount;

          return (
            <TabGrid key={`under-${deposit.amount}-${index}`}>
              <CircleText>
                {deposit.cardCompany} ({deposit.date})
              </CircleText>
              <SubPriceText>-{repayment.toLocaleString()}원</SubPriceText>
            </TabGrid>
          );
        })}
        <TabGrid>
          <SubTitleText>배달플랫폼 추가비용 상세내역</SubTitleText>
        </TabGrid>
        {baeminAds.map((ad, index) => (
          <TabGrid key={`ad-${ad.amount}-${index}`}>
            <CircleText>
              {ad.adType} ({ad.startDate}~{ad.endDate})
            </CircleText>
            <SubPriceText>-{ad.amount.toLocaleString()}원</SubPriceText>
          </TabGrid>
        ))}
        <TabGrid>
          <SubTitleText>요기요 추가비용 상세내역</SubTitleText>
        </TabGrid>
        {yogiyoAds.map((ad, index) => (
          <TabGrid key={`ad-${ad.amount}-${index}`}>
            <CircleText>
              {ad.adType} ({ad.startDate}~{ad.endDate})
            </CircleText>
            <SubPriceText>-{ad.amount.toLocaleString()}원</SubPriceText>
          </TabGrid>
        ))}
      </div>

      <TabGrid>
        <TitleText>총 선 정산금</TitleText>
        <PriceText>{totalEarlypay.toLocaleString()}원</PriceText>
      </TabGrid>

      <TabGrid>
        <TitleText>추가정산금</TitleText>
        <PriceText>{totalExtra.toLocaleString()}원</PriceText>
      </TabGrid>
      <div>
        {extra.map((pay, index) => (
          <TabGrid key={`extra-${pay.amount}-${index}`}>
            <CircleText>
              {pay.reason} ({pay.paymentStatus})
            </CircleText>
            <SubPriceText>{pay.amount.toLocaleString()}원</SubPriceText>
          </TabGrid>
        ))}
        <TabGrid>
          <SubTitleText>과정산 리스트</SubTitleText>
        </TabGrid>
        {over.map((deposit, index) => (
          <TabGrid key={`deposit-${deposit.amount}-${index}`}>
            <CircleText>{deposit.cardCompany}</CircleText>
            <SubPriceText>{deposit.amount.toLocaleString()}원</SubPriceText>
          </TabGrid>
        ))}
      </div>
    </div>
  );
};

export default AnotherEarlypay;
