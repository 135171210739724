import styled from "styled-components";
import theme from "src/styles/theme";

export const DetailUserInfoWrapper = styled.div`
  width: 100%;

  h6 {
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    background: ${theme.color.blue_20};
    padding: 10px;
  }
`;

export const Sections = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid #ddd;
  padding: 10px 5px;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
    border-bottom: none;
    padding: 0 5px;
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 140px 1fr;
  align-items: center;

  @media (max-width: 1100px) {
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
  }
`;

export const RowTitle = styled.div`
  font-weight: normal;
  color: #666;
  font-size: 13px;
`;

export const ColumnTitle = styled.div`
  font-weight: 500;
  color: #333;
  font-size: 15px;
`;

export const RowContent = styled.div`
  color: #111;
  font-weight: 400;
  font-size: 14px;
`;

export const Link = styled.a`
  color: ${theme.color.blue_10};
  font-weight: 500;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

export const Badge = styled.div`
  width: 18px;
  height: 18px;
  background: ${(props: { [_bg: string]: boolean }) => (props._bg ? "#5BB734" : "#e23e41")};
  border-radius: 50%;
  font-size: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1px;
  font-weight: 500;
`;
