import { atom } from "recoil";

export const storeListState = atom({
  key: "StoreList",
  default: [],
});

export const storeDetailState = atom({
  key: "StoreDetail",
  default: {
    ok: true,
    id: 1,
    title: "",
    user: {
      name: "",
      phone: "",
    },
    businessRegistrationNumber: "",
    pricePolicy: "",
    serviceStatus: "",
    cfaAccount: "",
    deliveryAccounts: [
      {
        platform: "배달의민족",
        identification: "",
        password: "",
      },
      {
        platform: "요기요",
        identification: "",
        password: "",
      },
    ],
    firstEarlypayDate: "",
    underDeposit: 0,
    platforms: [
      {
        platform: "삼성",
        isConnected: true,
        isDisabled: false,
      },
      {
        platform: "우리",
        isConnected: true,
        isDisabled: false,
      },
      {
        platform: "요기요",
        isConnected: true,
        isDisabled: false,
      },
      {
        platform: "국민",
        isConnected: true,
        isDisabled: false,
      },
      {
        platform: "비씨",
        isConnected: true,
        isDisabled: false,
      },
      {
        platform: "하나",
        isConnected: true,
        isDisabled: false,
      },
      {
        platform: "롯데",
        isConnected: true,
        isDisabled: false,
      },
      {
        platform: "현대",
        isConnected: true,
        isDisabled: false,
      },
      {
        platform: "농협",
        isConnected: true,
        isDisabled: false,
      },
      {
        platform: "신한",
        isConnected: true,
        isDisabled: false,
      },
      {
        platform: "쿠팡이츠",
        isConnected: false,
        isDisabled: false,
      },
      {
        platform: "배달의민족",
        isConnected: true,
        isDisabled: false,
      },
    ],
  },
});
