import React from "react";

import styled from "styled-components";

interface LinePropsTyep {
  _text?: string;
  _noText?: boolean;
  _borderType?: string;
  _color?: string;
  _bottom?: boolean;
}

const HorizonLine = ({ _text, _noText, _borderType = "solid", _color = "#e6e8ed", _bottom }: LinePropsTyep) => {
  if (_noText) {
    return <HorizonLineStyle _borderType={_borderType} _color={_color} _bottom={_bottom} />;
  }

  return (
    <HorizonLineStyle _borderType={_borderType} _color={_color} _bottom={_bottom}>
      <span style={{ background: "#fff", padding: "0 10px" }}>{_text}</span>
    </HorizonLineStyle>
  );
};

export default HorizonLine;

type HorizonLineStyleProps = {
  [key: string]: string | number;
};

const HorizonLineStyle = styled.div`
  width: 100%;
  text-align: center;
  border-bottom-width: 1px;
  border-bottom-color: ${(props: HorizonLineStyleProps) => props._color};
  border-bottom-style: ${(props: HorizonLineStyleProps) => props._borderType};
  line-height: 0.1em;
  margin: 12px 0;
  margin-bottom: ${(props: HorizonLineStyleProps) => props._bottom && "0px"};
`;
