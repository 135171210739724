import Text from "@/components/elements/Text";
import { themes } from "@carbon/themes";
import {
  ConnectSalesWrapper,
  CustomToggle,
  ToggleWrapper,
} from "@/components/features/settlement/StoreInfoBox/index.styled";
import { Control, Controller } from "react-hook-form";
import React from "react";
import { VanPlatformProps } from "@/types/storeType";

export interface ConnectedPlatformListProps {
  fields: VanPlatformProps[];
  control: Control<{ serviceStatus: string; platforms: VanPlatformProps[] }>;
  isCloseService: boolean;
}

export const ConnectedPlatformList: React.FC<ConnectedPlatformListProps> = ({ fields, control, isCloseService }) => {
  const vanPlatformFields = fields.filter(
    item => item.platform !== "쿠팡이츠" && item.platform !== "요기요" && item.platform !== "배달의민족",
  );
  const deliveryPlatformFields = fields.filter(
    item => item.platform === "요기요" || item.platform === "배달의민족" || item.platform === "쿠팡이츠",
  );

  return (
    <ConnectSalesWrapper>
      <Text size={12} color={themes.white.textSecondary}>
        연동 카드사 / 배달플랫폼
      </Text>
      {/** 연동 카드사 토글 스위치 */}
      <ToggleWrapper>
        {vanPlatformFields.map((item, index) => {
          return (
            <Controller
              key={index}
              control={control}
              name={`platforms.${index}.isDisabled`}
              render={({ field: { onChange, value, ref } }) => (
                <CustomToggle
                  id={`toggle-label-${item.platform}`}
                  innerRef={ref}
                  size="sm"
                  hideLabel
                  labelText={item.platform}
                  labelA="Off"
                  labelB="On"
                  isConnected={item.isConnected}
                  toggled={!value && !isCloseService && item.isConnected}
                  onClick={() => !isCloseService && item.isConnected && onChange(!value)}
                />
              )}
            />
          );
        })}
      </ToggleWrapper>

      {/** 연동 배달플랫폼 토글 스위치 */}
      <ToggleWrapper>
        {deliveryPlatformFields.map((item, index) => (
          <Controller
            key={index}
            control={control}
            name={`platforms.${index + vanPlatformFields.length}.isDisabled`}
            render={({ field: { onChange, value, ref } }) => (
              <CustomToggle
                id={`toggle-label-${item.platform}`}
                innerRef={ref}
                size="sm"
                hideLabel
                labelText={item.platform}
                labelA="Off"
                labelB="On"
                isConnected={item.isConnected}
                toggled={!value && !isCloseService && item.isConnected}
                onClick={() => !isCloseService && item.isConnected && onChange(!value)}
              />
            )}
          />
        ))}
      </ToggleWrapper>
    </ConnectSalesWrapper>
  );
};

export default ConnectedPlatformList;
