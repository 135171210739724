import { getDate, getTime } from "@/utils/getFormattedDate";

export const defaultValues = {
  deposits: [
    {
      depositedAt: `${getDate("now", "yyyy-mm-dd")}T${getTime("now")}`,
      cardCompany: "기타",
      amountDeposit: null,
      trxType: "입금",
    },
  ],
};
