import React from "react";

import { UtmChartBox, ApiCountBox, OldUtmBox, MonthUtmBox, CohortBox } from "src/components/module/funnel/index";
import { Wrap } from "./index.styled";

const Funnel = () => {
  return (
    <Wrap>
      <ApiCountBox />
      <UtmChartBox />
      <MonthUtmBox />
      <CohortBox />
      <OldUtmBox />
    </Wrap>
  );
};

export default Funnel;
