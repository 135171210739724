import React from "react";

import type { UserColumnsInterface } from "src/pages/Admin/Earlypay";
import type { StoreColumnsInterface } from "src/pages/Admin/Store";
import { List, ListTableWrapper, TableHeader } from "./index.styed";

interface ListTableInsterface {
  _columns: string[];
  _storeList: StoreColumnsInterface[] | UserColumnsInterface[];
  _idClickHandler: (id: number, created?: string) => void;
}

const ListTable = ({ _columns, _storeList, _idClickHandler }: ListTableInsterface) => {
  return (
    <ListTableWrapper>
      <TableHeader columnsLenght={_columns.length}>
        {_columns?.map(column => {
          return <div key={column}>{column}</div>;
        })}
      </TableHeader>
      {_storeList?.map(list => {
        const store = Object?.values(list);
        const created = list.created;

        return (
          <List key={list.id} columnsLenght={_columns.length}>
            {store.map((store: number, index) => (
              <div
                onClick={() => {
                  if (index === 0) _idClickHandler(store, created);
                }}
                key={`${list.id}-${list.id}-${index}`}
              >
                {store}
              </div>
            ))}
          </List>
        );
      })}
    </ListTableWrapper>
  );
};

export default ListTable;
