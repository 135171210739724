/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
// api
import { get_old_utm_funnel } from "src/apis/chart";
// chart
import ReactApexChart from "react-apexcharts";
// type
import { Option } from "src/types/graphType";
// component(base)
import { ChartCard } from "src/components/block";
// utill
import { FlexBox } from "@/components/atoms";
import { formatingDate } from "@/utils/date";
import styled from "styled-components";
// csv
import { CSVLink } from "react-csv";
import Text from "@/components/elements/Text";

const UtmChartBox = () => {
  const [utm, setUtm] = useState<any>([]);

  const categories = ["회원가입", "사업자번호", "본인인증", "경남은행", "서비스신청", "승인", "해지", "선정산중"];

  const getUtm = async () => {
    const { data } = await get_old_utm_funnel(formatingDate(new Date("2021/08/31")), formatingDate(new Date()));

    const arr: any[] = [];
    const allObj = { name: "전체", data: [0, 0, 0, 0, 0, 0, 0, 0] };

    data.map((x: any) => {
      if (x.utm === "MANUAL_CHANGE") x.utm = "MANUAL";

      allObj.data[0] = allObj.data[0] + x.totalUser;
      allObj.data[1] = allObj.data[1] + x.totalBusinessNumber;
      allObj.data[2] = allObj.data[2] + x.totalVerification;
      allObj.data[3] = allObj.data[3] + x.totalBnk;
      allObj.data[4] = allObj.data[4] + x.totalSubmit;
      allObj.data[5] = allObj.data[5] + x.totalActivate + x.totalSecede;
      allObj.data[6] = allObj.data[6] + x.totalSecede;
      allObj.data[7] = allObj.data[7] + x.totalEarlypay;

      arr.push({
        name: x.utm ?? "null",
        data: [
          x.totalUser,
          x.totalBusinessNumber,
          x.totalVerification,
          x.totalBnk,
          x.totalSubmit,
          x.totalActivate + x.totalSecede,
          x.totalSecede,
          x.totalEarlypay,
        ],
      });
    });

    arr.push(allObj);
    const all = arr.pop();
    const list = [];
    list.push(all);
    list.push(...arr);
    setUtm([...list]);
  };

  useEffect(() => {
    getUtm();
  }, []);

  const series = [...utm];

  const optionss: Option = {
    chart: {
      height: 350,
      type: "heatmap",
      toolbar: {
        export: {
          csv: {
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#fff"],
      },
    },
    xaxis: {
      type: "category",
      categories: [...categories],
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "명";
        },
      },
    },
    plotOptions: {
      heatmap: {
        radius: 3,
        enableShades: false,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: "#fff",
            },
          ],
        },
      },
    },
  };

  const [conversionUtm, setConversionUtm] = useState([]);
  const [churnUtm, setChurnUtm] = useState([]);
  const [list, setList] = useState(true);

  useEffect(() => {
    const conversion =
      utm?.map((x: any, idx: number) => {
        const change = Math.floor((x.data[5] / x.data[0]) * 100);
        return {
          name: x.name,
          count: x.data[5],
          per: change,
          id: `utm-${idx}`,
        };
      }) ?? [];

    const churn =
      utm?.map((x: any, idx: number) => {
        const change = Math.floor((x.data[6] / x.data[5]) * 100);
        return {
          name: x.name,
          count: x.data[6],
          per: change,
          id: `utm-${idx}`,
        };
      }) ?? [];

    conversion.sort((a: any, b: any) => b.count - a.count);
    churn.sort((a: any, b: any) => b.count - a.count);

    setConversionUtm([...conversion]);
    setChurnUtm([...churn]);
  }, [utm]);

  const [dropDown, setDropDown] = useState([
    { key: "유저수", act: true },
    { key: "비율", act: false },
  ]);

  const chageChart = (arr: { key: string; act: boolean }[]) => {
    setDropDown([...arr]);
    const trueKey = arr.filter(x => {
      if (x.act === true) return x;
    });

    if (trueKey[0].key === "유저수") {
      setList(true);
    } else {
      setList(false);
    }
  };

  useEffect(() => {
    const mark = "rgba(255, 242, 0, 0.5)";
    conversionUtm.map((x, idx) => {
      const utm = document?.getElementById(`utm-${idx}`);
      const utmR = document?.getElementById(`utm-${idx}-r`);
      const utmP = document?.getElementById(`utm-${idx}p`);
      const utmPR = document?.getElementById(`utm-${idx}-r-p`);
      const onHover = () => {
        utm.style.background = mark;
        utmR.style.background = mark;
        utmP.style.background = mark;
        utmPR.style.background = mark;
      };
      const overHover = () => {
        utm.style.background = "#fff";
        utmR.style.background = "#fff";
        utmP.style.background = "#fff";
        utmPR.style.background = "#fff";
      };

      utm?.addEventListener("mouseover", onHover);
      utm?.addEventListener("mouseleave", overHover);
      utmR?.addEventListener("mouseover", onHover);
      utmR?.addEventListener("mouseleave", overHover);
      utmP?.addEventListener("mouseover", onHover);
      utmP?.addEventListener("mouseleave", overHover);
      utmPR?.addEventListener("mouseover", onHover);
      utmPR?.addEventListener("mouseleave", overHover);
    });
  }, [conversionUtm]);

  const csvList = utm.map((x: any) => {
    return [x.name, ...x.data];
  });
  const csvData = [
    [
      "카테고리",
      "회원가입",
      "상담신청",
      "사업자번호",
      "본인인증",
      "경남은행",
      "서비스신청",
      "승인",
      "해지",
      "선정산중",
    ],
    ...csvList,
  ];

  return (
    <UtmWrap style={{ width: "100%" }}>
      <ChartCard _dropdown={false} _title="총 UTM" _date={false}>
        <Text size={18} bold>
          UTM별 퍼널 분석
        </Text>
        <div style={{ textAlign: "end" }}>
          <CSVLink data={csvData}>CSV download</CSVLink>
        </div>
        <div style={{ margin: "0 -20px" }}>
          <ReactApexChart options={optionss} series={series} type="heatmap" height={450} />
        </div>
      </ChartCard>
      <ChartCard _date={false} _title="총 전환율&해지율" _dropDownArr={dropDown} _onClick={chageChart}>
        <ListBox _list={list}>
          <div>
            <UtmTitle>
              <Text size={18} bold>
                UTM별 전환율
              </Text>
            </UtmTitle>
            {conversionUtm
              .sort((a: any, b: any) => b.count - a.count)
              .map((x: any, idx: number) => {
                return (
                  <HoverDiv key={idx} id={x.id}>
                    <UtmText _idx={idx}>
                      <div>{x.name ? x.name : "null"}</div>
                      <div>
                        {x.count}명 ({x.per}%)
                      </div>
                    </UtmText>
                  </HoverDiv>
                );
              })}
          </div>
          <div>
            <UtmTitle>
              <Text size={18} bold>
                UTM별 해지율
              </Text>
            </UtmTitle>
            {churnUtm
              .sort((a: any, b: any) => b.count - a.count)
              .map((x: any, idx: number) => {
                return (
                  <HoverDiv key={idx} id={x.id + "-r"}>
                    <UtmText _idx={idx}>
                      <div> {x.name ? x.name : "null"}</div>
                      <div>
                        {x.count}명 ({x.per}%)
                      </div>
                    </UtmText>
                  </HoverDiv>
                );
              })}
          </div>
        </ListBox>
        <ListBox _list={!list}>
          <div>
            <UtmTitle>
              <Text size={18} bold>
                UTM별 전환율
              </Text>
            </UtmTitle>
            {conversionUtm
              .sort((a: any, b: any) => b.per - a.per)
              .map((x: any, idx: number) => {
                return (
                  <HoverDiv key={idx} id={x.id + "p"}>
                    <UtmText _idx={idx}>
                      <div>{x.name ? x.name : "null"}</div>
                      <div>
                        {x.count}명 ({x.per}%)
                      </div>
                    </UtmText>
                  </HoverDiv>
                );
              })}
          </div>
          <div>
            <UtmTitle>
              <Text size={18} bold>
                UTM별 해지율
              </Text>
            </UtmTitle>
            {churnUtm
              .sort((a: any, b: any) => b.per - a.per)
              .map((x: any, idx: number) => {
                return (
                  <HoverDiv key={idx} id={x.id + "-r-p"}>
                    <UtmText _idx={idx}>
                      <div> {x.name ? x.name : "null"}</div>
                      <div>
                        {x.count}명 ({x.per}%)
                      </div>
                    </UtmText>
                  </HoverDiv>
                );
              })}
          </div>
        </ListBox>
      </ChartCard>
    </UtmWrap>
  );
};

export default UtmChartBox;

const UtmWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin: 12px 0;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;

const UtmText = styled.div`
  width: 100%;
  font-size: 15px;
  padding: 2px;
  display: flex;
  justify-content: space-between;

  border-top: 1px solid #eee;

  span {
    font-size: 13px;
    width: 100%;
  }
`;

const ListBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  display: ${(props: { [key: string]: boolean }) => (props._list ? "grid" : "none")};
`;

const UtmTitle = styled(FlexBox)`
  justify-content: space-between;
  margin-bottom: 20px;

  span {
    font-size: 12px;
    color: #555;
  }
`;

const HoverDiv = styled.div`
  width: 100%;
`;
