import Text from "@/components/elements/Text";
import SearchStoreBox from "@/components/features/settlement/SearchStoreBox";
import { getDate } from "@/utils/getFormattedDate";
import { CalendarSettings as CalendarSettingsIcon, Search as SearchIcon } from "@carbon/icons-react";
import { Button, DatePickerInput, Stack } from "@carbon/react";
import { themes } from "@carbon/themes";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { DatePickerWrapper, SearchStack, SearchWrapper } from "./index.styled";
import { useNavigateSearch } from "@/hooks/useNavigateSearch";

export const SearchDetailBox: React.FC = () => {
  const { navigateSearch } = useNavigateSearch();
  const location = useLocation();
  const pathname = location.pathname.split("/");
  const searchParams = new URLSearchParams(location.search);
  const storeId = pathname.pop();
  const searchKeyword = searchParams.get("search") || "";
  const start_date = searchParams.get("start_date") || "";
  const end_date = searchParams.get("end_date") || "";
  const today = getDate("now", "yyyy-mm-dd");

  const [startDate, setStartDate] = useState<string | Date>(today);
  const [endDate, setEndDate] = useState<string | Date>(today);
  const [dateFocus, setDateFocus] = useState<string>("start");

  const onClickSearchButton = () => {
    const formattedStartDate = getDate(startDate, "yyyy-mm-dd");
    const formattedEndDate = getDate(endDate, "yyyy-mm-dd");
    navigateSearch(`/settlement/detail/${storeId}`, {
      search: searchKeyword,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
    });
  };

  /** 날짜 초기화 함수 */
  /** 날짜를 선택할 때는 undefined 값이 들어오고, 날짜 초기화 버튼을 눌렀을 때는 '당일' 날짜로 설정됩니다. */
  const onResetDate = () => {
    setStartDate(today);
    setEndDate(today);
  };

  /** 회수예정날짜 시작, 종료 변경 함수 */
  const onChangeDate = (dates: Date[]) => {
    dateFocus === "start" && setStartDate(dates[0]);
    dateFocus === "end" && setEndDate(dates[1]);
  };

  /** url 파라미터 값을 다이렉트로 변경했을 경우, start_date와 end_date 값을 읽어와서 초기 값으로 설정합니다. */
  useEffect(() => {
    setStartDate(start_date);
    setEndDate(end_date);
  }, [location.search]);

  return (
    <SearchWrapper>
      <SearchStack>
        <Stack gap="8px">
          <Text size={12} color={themes.white.textSecondary}>
            가게 검색
          </Text>
          <SearchStoreBox placeholder="가게이름, 고객이름, 사업자로 검색" />
        </Stack>

        <DatePickerWrapper
          locale="ko"
          dateFormat={`Y.m.d`}
          datePickerType="range"
          value={[startDate, endDate]}
          onChange={onChangeDate}
          allowInput={false}
          maxDate={dateFocus === "start" ? endDate : null}
          minDate={dateFocus === "end" ? startDate : null}
        >
          <DatePickerInput
            id="date-picker-input-id-start"
            placeholder="yyyy.mm.dd"
            labelText="회수예정날짜 시작"
            size="lg"
            onFocus={() => setDateFocus("start")}
          />
          <DatePickerInput
            id="date-picker-input-id-finish"
            placeholder="yyyy.mm.dd"
            labelText="회수예정날짜 종료"
            size="lg"
            onFocus={() => setDateFocus("end")}
          />
        </DatePickerWrapper>

        <Button
          as="button"
          kind="tertiary"
          renderIcon={CalendarSettingsIcon}
          iconDescription="가게 날짜 초기화 버튼"
          type="button"
          onClick={onResetDate}
        >
          날짜 초기화
        </Button>
      </SearchStack>

      <Button
        as="button"
        kind="primary"
        renderIcon={SearchIcon}
        iconDescription="가게 검색 버튼"
        type="button"
        onClick={onClickSearchButton}
        isDisabled={!startDate || !endDate}
      >
        검색
      </Button>
    </SearchWrapper>
  );
};

export default SearchDetailBox;
