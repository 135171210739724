import React, { useState } from "react";
import styled from "styled-components";

import { useLocation } from "react-router";

import { DetailUserInfo, Bizppurio, DailyEarlypay, PlatformSale, CfaSale } from "src/components/module/admin";

const Wrap = styled.div`
  width: 100%;
  min-height: calc(100vh - 100px);
  height: 100%;
  margin-top: 100px;
  padding: 12px 100px;

  @media (max-width: 1100px) {
    padding: 12px 30px;
  }

  @media (max-width: 480px) {
    padding: 12px 10px;
  }
`;

const EarlypayBox = styled.div`
  display: grid;
  grid-template-columns: calc((100% - 10px) / 2) calc((100% - 10px) / 2);
  gap: 10px;
  margin-bottom: 20px;

  @media (max-width: 1100px) {
    grid-template-columns: 100%;
  }
`;

export interface DetailPropsInterface {
  _storeId: number;
  _date: string;
}

const Detail = () => {
  const location = useLocation();
  const params = location.search.replace("?", "").split("&");
  const storeId = Number(params[1].split("=")[1]);
  const date = params[2].split("=")[1];
  const [cardList, setCardList] = useState<string[]>([]);

  return (
    <Wrap>
      <EarlypayBox>
        <Bizppurio _storeId={storeId} _date={date} />
        <DailyEarlypay _storeId={storeId} _date={date} />
        <PlatformSale _storeId={storeId} _date={date} _setCardList={setCardList} />
        <CfaSale _storeId={storeId} _date={date} _cardList={cardList} />
      </EarlypayBox>
      <DetailUserInfo _storeId={storeId} />
    </Wrap>
  );
};

export default Detail;
