import { DatePicker } from "@carbon/react";
import styled from "styled-components";

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
`;

export const DatePickerWrapper = styled(DatePicker)`
  display: flex;
  flex: 0 0 auto;

  .cds--date-picker--range {
    gap: 8px;
  }
`;

export const SearchStack = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: end;
  gap: 8px;
`;
