import { ComposedModal, Select, Table, TableCell, TableRow, TextInput } from "@carbon/react";
import styled from "styled-components";

export const CustomModal = styled(ComposedModal)``;

export const ModalWrapper = styled.div`
  padding: 8px;
  width: 100%;
  height: 100%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .cds--data-table-content {
    width: 100%;
  }
`;

export const EditModalWrapper = styled.div`
  padding: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px;

  .cds--data-table-content {
    width: 100%;
  }
`;

export const DepositDetailTable = styled(Table)``;

export const CustomTableRow = styled(TableRow)`
  height: fit-content !important;

  > td {
    padding-inline-end: 0px;
    padding-inline-start: 0px;
  }
`;

export const CustomTableCell = styled(TableCell)`
  padding: 0px;
`;

export const CustomSelect = styled(Select)`
  background-color: red;
`;

export const CustomTextInput = styled(TextInput)``;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SubmitButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
