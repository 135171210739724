import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { CSVLink } from "react-csv";

import { get_utm_funnel } from "src/apis/chart";

import { ChartCard, PieChart } from "src/components/block";
import { CheckBox, UtmWrap } from "./index.styled";

import { formatingDate } from "@/utils/date";
import type { UtmType } from "src/types/apiType";
import { Option } from "src/types/graphType";

const MonthUtmBox = () => {
  const [checkbox, setCheckbox] = useState<{ name: string; act: boolean }[]>([]);
  const [utms, setUtms] = useState<{ name: string; data: number[] }[]>([]);
  const [pieChart, setPieChart] = useState<{ [key: string]: number }>({});
  const [date, setDate] = useState<{ start: Date; end: Date }>({
    start: new Date("2023-04-01"),
    end: new Date(),
  });

  const series = [...utms];
  const utmArr: { name: string; act: boolean }[] = [];
  const csvList = utms.map(utm => [utm.name, ...utm.data]);
  const csvData = [
    [
      "카테고리",
      "회원가입",
      "상담신청",
      "본인인증",
      "사업자번호",
      "경남은행",
      "서비스신청",
      "매출연동",
      "승인",
      "해지",
      "선정산중",
    ],
    ...csvList,
  ];
  const categories = [
    "회원가입",
    "상담신청",
    "본인인증",
    "사업자번호",
    "경남은행",
    "서비스신청",
    "매출연동",
    "승인",
    "해지",
    "선정산중",
  ];

  const options: Option = {
    chart: { height: 350, type: "heatmap" },
    dataLabels: {
      enabled: true,
      style: { colors: ["#fff"] },
    },
    xaxis: {
      type: "category",
      categories: [...categories],
    },
    tooltip: { y: { formatter: val => val + "명" } },
    plotOptions: {
      heatmap: {
        radius: 3,
        enableShades: false,
        colorScale: {
          ranges: [{ from: 0, to: 0, color: "#fff" }],
        },
      },
    },
  };

  const getUtm = async () => {
    const data = await get_utm_funnel(formatingDate(date.start), formatingDate(date.end), "month");
    return data;
  };

  const setCheckBox = (utmName: string) => {
    if (checkbox.length > 0) return;
    const check = { name: utmName, act: utmName === "pincrux" ? true : false };
    utmArr.push({ ...check });
    setCheckbox(utmArr);
  };

  const setChartData = async (utmData: UtmType[], utmList?: { name: string; act: boolean }[]) => {
    const defaultList: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const defaultPieChartData: { [key: string]: number } = {};
    const utmLabels = [...new Set(utmData.map(utm => (utm.utm === null ? "null" : utm.utm)))];
    const activeList = utmList?.filter(utm => !utm.act && utm).map(utm => utm.name) ?? [...utmLabels];

    const dateList = [...new Set(utmData.map(utm => utm.date))].map(date => {
      const utmList: UtmType[] = [];
      return { date: date, utm: utmList };
    });

    utmLabels.forEach(utm => {
      defaultPieChartData[utm] = 0;
      setCheckBox(utm);
    });

    utmData.forEach(utm => {
      const index = dateList.findIndex(date => date.date === utm.date);
      dateList[index].utm.push(utm);
    });

    const userList: { name: string; data: number[] }[] = dateList.map(utms => {
      const users = utms.utm.reduce(
        (funnel, utm) => {
          const utmName = utm.utm === null ? "null" : utm.utm;
          if (checkbox.length === 0 && utmName === "pincrux") {
            return funnel;
          } else if (!activeList.includes(utmName)) {
            return funnel;
          }
          defaultPieChartData[utmName] += utm.totalActivate;

          funnel[0] += utm.totalUser;
          funnel[1] += utm.totalConsulting;
          funnel[2] += utm.totalVerification;
          funnel[3] += utm.totalBusinessNumber;
          funnel[4] += utm.totalBnk;
          funnel[5] += utm.totalSubmit;
          funnel[6] += utm.totalSalesConnected;
          funnel[7] += utm.totalActivate;
          funnel[8] += utm.totalSecede;
          funnel[9] += utm.totalEarlypay;
          return funnel;
        },
        [...defaultList],
      );

      return { name: utms.date, data: [...users] };
    });

    userList.map(utm => {
      defaultList[0] += utm.data[0];
      defaultList[1] += utm.data[1];
      defaultList[2] += utm.data[2];
      defaultList[3] += utm.data[3];
      defaultList[4] += utm.data[4];
      defaultList[5] += utm.data[5];
      defaultList[6] += utm.data[6];
      defaultList[7] += utm.data[7];
      defaultList[8] += utm.data[8];
      defaultList[9] += utm.data[9];
    });

    const allObj = { name: "전체", data: [...defaultList] };

    setPieChart(defaultPieChartData);
    setUtms([allObj, ...userList.reverse()]);
  };

  const createUtmChart = async (utmList?: { name: string; act: boolean }[]) => {
    const utmData = await getUtm();
    setChartData(utmData, utmList);
  };

  useEffect(() => {
    createUtmChart();
  }, [date]);

  return (
    <UtmWrap style={{ marginBottom: "10px", overflow: "hidden" }}>
      <ChartCard _dropdown={false} _title="월별 UTM" _date={true} _dateObj={date} _setDateObj={setDate} _month={true}>
        {/* TODD: 추후 컴포넌트 통합시 변경필요 */}
        <CheckBox>
          {checkbox.map(utm => {
            return (
              <div
                key={utm.name}
                onClick={() => {
                  const copy = checkbox.map(x => {
                    if (x.name === utm.name) return { name: x.name, act: !x.act };
                    return x;
                  });
                  setCheckbox(copy);
                  createUtmChart(copy);
                }}
              >
                <div>{utm.name}</div>
                <div
                  style={{
                    width: "14px",
                    height: "14px",
                    borderRadius: "2px",
                    background: utm.act ? "#fff" : "#3486ff",
                    border: "1px solid #aaa",
                    color: "#fff",
                    fontSize: "11px",
                    display: "flex",
                    alignItems: "center",
                    lineHeight: 2,
                  }}
                >
                  v
                </div>
              </div>
            );
          })}
        </CheckBox>
        <div style={{ textAlign: "end" }}>
          <CSVLink data={csvData}>CSV download</CSVLink>
        </div>
        <div style={{ margin: "0 -20px" }}>
          <ReactApexChart options={options} series={series} type="heatmap" height={450} />
        </div>
      </ChartCard>
      <ChartCard _title="총 승인유저 합계 분포" _date={false} _dropdown={false}>
        <PieChart _chartType={2} _labels={[...Object.keys(pieChart)]} _series={[...Object.values(pieChart)]} />
      </ChartCard>
    </UtmWrap>
  );
};

export default MonthUtmBox;
