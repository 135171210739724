import styled from "styled-components";

import { FlexBox } from "@/components/atoms";
import theme from "src/styles/theme";

export const HeaderWrap = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${theme.zIndex.z_4};
  background: #fff;
`;

export const HeaderTop = styled(FlexBox)`
  background: #0078d4;
  padding: 16px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 100px;

  @media (max-width: 1100px) {
    padding: 16px 30px;
  }

  @media (max-width: 480px) {
    padding: 16px 10px;
  }

  h1 {
    color: #fff;
  }
  p {
    color: #fff;
    cursor: pointer;
    font-weight: 500;
  }
`;

export const HeaderMiddle = styled(FlexBox)`
  justify-content: center;
  padding: 0px 12px;
  border-bottom: 1.5px solid #ececec;
  gap: 30px;
`;

export const Nav = styled.button`
  background: #fff;
  height: 46px;
  color: #ccc;
  border-bottom: ${(props: { [key: string]: string }) => (props._active ? "3px solid #0078d4" : "none")};
  color: ${(props: { [key: string]: string }) => (props._active ? "#333" : "#ccc")};
`;
