import { Stack } from "@carbon/react";
import Modal from "react-modal";
import styled from "styled-components";

export const CustomModal = styled(Modal)``;

export const ModalWrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: calc(100% - 40px);
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

export const CloseStack = styled(Stack)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .cds--popover {
    display: none;
  }
`;

export const DataStack = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;
