import { useLocation, useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";

export const useNavigateSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateSearch = (pathname: string, params?: { [key: string]: string }) => {
    const currentPathname = location.pathname;
    const currentParams = location.search ? location.search : "?";

    /** 동일한 페이지로 이동하려는 경우 navigate 막기 */
    if (currentPathname === pathname && currentParams === `?${createSearchParams(params)}`) {
      return;
    }

    return navigate({ pathname, search: params && `?${createSearchParams(params)}` });
  };

  return { navigateSearch };
};
