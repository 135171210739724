/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { CSVLink } from "react-csv";
import styled from "styled-components";

import { get_utm_funnel } from "src/apis/chart";

import { ChartCard } from "src/components/block";

import { formatingDate } from "@/utils/date";
import { Option } from "src/types/graphType";
import Text from "@/components/elements/Text";

const UtmWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin: 12px 0;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;

const CheckBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  div {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
  }
`;

const UtmChartBox = () => {
  const [utm, setUtm] = useState<any>([]);
  const [date, setDate] = useState<any>({ start: new Date(), end: new Date() });
  const [checkbox, setCheckbox] = useState<any[]>([]);

  const categories = [
    "회원가입",
    "상담신청",
    "본인인증",
    "사업자번호",
    "경남은행",
    "서비스신청",
    "매출연동",
    "승인",
    "해지",
    "선정산중",
  ];

  const getUtm = async () => {
    const data = await get_utm_funnel(formatingDate(date.start), formatingDate(date.end), "day");

    const resultArr: any | [] = [];

    data.map((element: { [key: string]: any }) => {
      const utmNameList: any[] = resultArr.map((x: any) => x.utm);

      if (!utmNameList.includes(element.utm)) {
        resultArr.push({ ...element });
      } else {
        const index = utmNameList.findIndex(x => x === element.utm);

        Object.keys(element).map(earlypayKey => {
          if (earlypayKey === "utm") return;
          resultArr[index][earlypayKey] = element[earlypayKey] + (resultArr[index][earlypayKey] ?? undefined);
        });
      }
    });

    const arr: any[] = [];
    const utmArr: any[] = [];
    const allObj = { name: "전체", data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] };

    resultArr.map((x: any) => {
      const check = { name: x.utm, act: x.utm === "pincrux" ? true : false };

      utmArr.push({ ...check });
      setCheckbox(utmArr);

      if (x.utm === "pincrux") return;
      if (x.utm === "MANUAL_CHANGE") x.utm = "MANUAL";
      if (x.utm === null) x.utm = "null";

      allObj.data[0] = allObj.data[0] + x.totalUser;
      allObj.data[1] = allObj.data[1] + x.totalConsulting;
      allObj.data[2] = allObj.data[2] + x.totalVerification;
      allObj.data[3] = allObj.data[3] + x.totalBusinessNumber;
      allObj.data[4] = allObj.data[4] + x.totalBnk;
      allObj.data[5] = allObj.data[5] + x.totalSubmit;
      allObj.data[6] = allObj.data[6] + x.totalSalesConnected;
      allObj.data[7] = allObj.data[7] + x.totalActivate;
      allObj.data[8] = allObj.data[8] + x.totalSecede;
      allObj.data[9] = allObj.data[9] + x.totalEarlypay;

      arr.push({
        name: x.utm ?? "null",
        data: [
          x.totalUser,
          x.totalConsulting,
          x.totalVerification,
          x.totalBusinessNumber,
          x.totalBnk,
          x.totalSubmit,
          x.totalSalesConnected,
          x.totalActivate,
          x.totalSecede,
          x.totalEarlypay,
        ],
      });
    });

    arr.push(allObj);
    const all = arr.pop();
    const list = [];
    list.push(all);
    list.push(...arr);
    setUtm([...list]);
  };

  useEffect(() => {
    getUtm();
    setCheckbox([]);
  }, [date]);

  const series = [...utm];

  const options: Option = {
    chart: { height: 350, type: "heatmap" },
    dataLabels: {
      enabled: true,
      style: { colors: ["#fff"] },
    },
    xaxis: {
      type: "category",
      categories: [...categories],
    },
    tooltip: {
      y: { formatter: val => val + "명" },
    },
    plotOptions: {
      heatmap: {
        radius: 3,
        enableShades: false,
        colorScale: {
          ranges: [{ from: 0, to: 0, color: "#fff" }],
        },
      },
    },
  };

  const [funnel, setFunnel] = useState<any>([]);
  const [drop, setDrop] = useState([]);

  useEffect(() => {
    const name = utm.map((x: any, idx: number) => {
      return { key: x.name, act: idx === 0 ? true : false };
    });
    setFunnel(name);
    const copy = series.filter(x => {
      if (x.name === "전체") return x;
    });
    setDrop(copy);
  }, [utm]);

  const changeDropdown = (arr: { key: string; act: boolean }[]) => {
    const select: any = arr.filter(x => {
      if (x.act === true) return x;
    })[0];

    setFunnel([...arr]);
    const copy = series.filter(x => {
      if (x.name === select.key) return x;
    });

    setDrop(copy);
  };

  const csvList = utm.map((x: any) => {
    return [x.name, ...x.data];
  });

  const csvData = [
    [
      "카테고리",
      "회원가입",
      "상담신청",
      "본인인증",
      "사업자번호",
      "경남은행",
      "서비스신청",
      "매출연동",
      "승인",
      "해지",
      "선정산중",
    ],
    ...csvList,
  ];

  const setUtmList = async (utmList?: any) => {
    const data = await get_utm_funnel(formatingDate(date.start), formatingDate(date.end), "day");

    const selectList =
      utmList
        ?.filter((x: any) => {
          if (x.act === true) return x.name;
        })
        .map((x: any) => x.name) ?? [];

    const resultArr: any | [] = [];

    data.map((element: { [key: string]: any }) => {
      const utmNameList: any[] = resultArr.map((x: any) => x.utm);

      if (!utmNameList.includes(element.utm)) {
        resultArr.push({ ...element });
      } else {
        const index = utmNameList.findIndex(x => x === element.utm);

        Object.keys(element).map(earlypayKey => {
          if (earlypayKey === "utm") return;
          resultArr[index][earlypayKey] = element[earlypayKey] + (resultArr[index][earlypayKey] ?? undefined);
        });
      }
    });

    const arr: any[] = [];
    const allObj = { name: "전체", data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] };

    resultArr.map((x: any) => {
      if (x.utm === "MANUAL_CHANGE") x.utm = "MANUAL";
      if (x.utm === null) x.utm = "null";
      if (selectList.includes(x.utm)) return;

      allObj.data[0] = allObj.data[0] + x.totalUser;
      allObj.data[1] = allObj.data[1] + x.totalConsulting;
      allObj.data[2] = allObj.data[2] + x.totalVerification;
      allObj.data[3] = allObj.data[3] + x.totalBusinessNumber;
      allObj.data[4] = allObj.data[4] + x.totalBnk;
      allObj.data[5] = allObj.data[5] + x.totalSubmit;
      allObj.data[6] = allObj.data[6] + x.totalSalesConnected;
      allObj.data[7] = allObj.data[7] + x.totalActivate;
      allObj.data[8] = allObj.data[8] + x.totalSecede;
      allObj.data[9] = allObj.data[9] + x.totalEarlypay;

      arr.push({
        name: x.utm ?? "null",
        data: [
          x.totalUser,
          x.totalConsulting,
          x.totalVerification,
          x.totalBusinessNumber,
          x.totalBnk,
          x.totalSubmit,
          x.totalSalesConnected,
          x.totalActivate,
          x.totalSecede,
          x.totalEarlypay,
        ],
      });
    });

    arr.push(allObj);
    const all = arr.pop();
    const list = [];
    list.push(all);
    list.push(...arr);
    setUtm([...list]);
  };

  const sortArr = series.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <UtmWrap style={{ width: "100%", overflow: "hidden" }}>
      <ChartCard _dropdown={false} _title="일일 UTM" _date={true} _dateObj={date} _setDateObj={setDate}>
        <Text size={16} bold>
          일일 UTM 퍼널별 사용자수
        </Text>

        <CheckBox>
          {checkbox.map(utm => {
            return (
              <div
                key={utm.name}
                onClick={() => {
                  const copy = checkbox.map(x => {
                    if (x.name === utm.name) return { name: x.name, act: !x.act };
                    return x;
                  });
                  setCheckbox(copy);
                  setUtmList(copy);
                }}
              >
                <div>{utm.name}</div>
                <div
                  style={{
                    width: "14px",
                    height: "14px",
                    borderRadius: "2px",
                    background: utm.act ? "#fff" : "#3486ff",
                    border: "1px solid #aaa",
                    color: "#fff",
                    fontSize: "11px",
                    display: "flex",
                    alignItems: "center",
                    lineHeight: 2,
                  }}
                >
                  v
                </div>
              </div>
            );
          })}
        </CheckBox>

        <div style={{ textAlign: "end" }}>
          <CSVLink data={csvData}>CSV 파일받기</CSVLink>
        </div>
        <div style={{ margin: "0 -20px" }}>
          <ReactApexChart options={options} series={sortArr} type="heatmap" height={450} />
        </div>
      </ChartCard>
      <ChartCard _date={false} _title="일일 UTM별 분석" _dropDownArr={[...funnel]} _onClick={changeDropdown}>
        <Text size={20} bold>
          UTM : {drop[0]?.name ?? "에러"}
        </Text>
        <Text size={14} bold>
          UTM별 퍼널 사용자 수 (한 유저의 가장 최신 현황을 카운트합니다.)
        </Text>
        <div style={{ margin: "0 -20px" }}>
          <ReactApexChart options={options} series={drop} type="bar" height={"450px"} />
        </div>
      </ChartCard>
    </UtmWrap>
  );
};

export default UtmChartBox;
