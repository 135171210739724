import React, { useState } from "react";
import { useNavigate } from "react-router";

import { post_admin_login } from "src/apis/user";

import { LoginButton, LoginForm, LoginInput, LoginText } from "./index.styled";

import { saveToken } from "@/utils/token";

const AdminLoginForm = () => {
  const navigate = useNavigate();

  const [useId, setUserId] = useState<string>("");
  const [userPW, setUserPw] = useState<string>("");

  const postLogin = async (id: string, pw: string): Promise<void | Error> => {
    try {
      const { data } = await post_admin_login(id, pw);
      saveToken(data.key);

      return navigate("/");
    } catch (err) {
      alert(err.response.data.errors);

      throw new Error("login page error");
    }
  };

  return (
    <LoginForm
      onSubmit={(e: Event) => {
        e.preventDefault();
        postLogin(useId, userPW);
      }}
    >
      <LoginText>사용자이름</LoginText>
      <LoginInput
        placeholder="ID를 입력해주세요"
        type="text"
        value={useId}
        onChange={(e: React.FormEvent<HTMLInputElement>) => setUserId(e.currentTarget.value)}
      />
      <LoginText>비밀번호</LoginText>
      <LoginInput
        placeholder="PW를 입력해주세요"
        type="password"
        value={userPW}
        onChange={(e: React.FormEvent<HTMLInputElement>) => setUserPw(e.currentTarget.value)}
        autoComplete="on"
      />
      <LoginButton>로그인</LoginButton>
    </LoginForm>
  );
};

export default AdminLoginForm;
