import styled from "styled-components";

export const AccordionOutBox = styled.div`
  border-radius: 10px;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
`;

export const ToggleTitle = styled.div`
  width: 100%;
  padding: 4px 0;
`;

export const ToggleInner = styled.div`
  transition: all 0.1s ease-in-out;
  height: 0px;
  overflow: hidden;
  width: 100%;
`;
