import { Button, Header, SideNav, Stack } from "@carbon/react";
import { themes } from "@carbon/themes";
import styled from "styled-components";

export const SettlementLayoutWrapper = styled(Stack)`
  overflow: hidden;
  height: calc(-100px + 100vh);
  min-height: calc(-100px + 100vh);
  margin-top: 100px;
`;

export const SettlementSideNavWrapper = styled(SideNav)`
  width: 25%;
  min-width: 25%;
  display: flex;
  border-left: 1px solid #e0e0e0;
  right: 0;
  left: auto;
  height: calc(-100px + 100vh);
  min-height: calc(-100px + 100vh);
  margin-top: 100px;
`;

export const HeaderWrapper = styled(Header)`
  padding: 0 32px;
`;

export const HeaderButton = styled(Button).attrs({
  iconDescription: "header-button",
})`
  justify-content: center;
  align-items: center;
  color: ${themes.white.textPrimary};
`;

export const SettlementContentWrapper = styled(Stack)`
  width: 75%;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  padding: 20px 20px 50px 20px;
`;
