import React from "react";
// component(base)
import { Logo } from "@/components/atoms";
import theme from "src/styles/theme";
// style
import styled from "styled-components";

const Spinner = () => {
  return (
    <SpinnerBackground>
      <Logo />
    </SpinnerBackground>
  );
};

export default Spinner;

const SpinnerBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: ${theme.zIndex.z_5};
  top: 0;
  left: 0;
  .css-33bpos {
    opacity: 0.5 !important;
  }
  p {
    position: absolute;
    font-weight: bold;
    color: #000;
    opacity: 0.2;
  }
`;
