import { storeListState } from "@/recoil/stores/atoms";
import { useQuery } from "react-query";
import { useSetRecoilState } from "recoil";
import { instance } from "../instance";

// 가게 목록을 조회합니다
export const get_store_list = async (page: number, page_size: number, search?: number | string) => {
  const params = { page, page_size, search };

  const { data } = await instance({
    method: "get",
    url: `/v2/admin/stores`,
    params: params,
  });

  return data;
};

export const useGetStoreList = (page: number, page_size: number, search?: number | string) => {
  const setStoreList = useSetRecoilState(storeListState);

  return useQuery(["StoreList", page, page_size, search], () => get_store_list(page, page_size, search), {
    enabled: !!search,
    onSuccess: data => setStoreList(data.results),
  });
};
