import { ContainedList, ContainedListItem, Search, Stack } from "@carbon/react";
import { themes } from "@carbon/themes";
import styled from "styled-components";

export const SearchStoreBoxWrapper = styled(Stack)`
  position: relative;
  z-index: 9;
  display: flex;
  width: 100%;
  gap: 8px;
`;

export const SearchListWrapper = styled(Stack)`
  position: absolute;
  background-color: ${themes.white.layer01};
  box-shadow: 0px 2px 6px 0px ${themes.white.shadow};
  gap: 0px;
  width: 358px;
`;

export const SearchItem = styled(ContainedListItem)`
  width: 100%;
  background-color: ${({ isSelected }: { isSelected: boolean }) =>
    isSelected ? themes.white.layerSelected01 : themes.white.layer01};
  border-bottom: 1px solid ${themes.white.layer02};
`;

export const CustomSearch = styled(Search)`
  border-block-end: 1px solid ${themes.white.borderStrong01};
  width: 100%;

  > input {
    background-color: ${themes.white.field01} !important;
  }
`;

export const SearchWrapper = styled(ContainedList)`
  width: 358px;
`;
