import DatePicker from "react-datepicker";
import styled from "styled-components";

import { FlexBox } from "@/components/atoms";
import theme from "src/styles/theme";

export const SelectBox = styled(FlexBox)`
  gap: 10px;
  justify-content: end;
  margin-bottom: 10px;

  .react-datepicker-wrapper {
    width: auto !important;
  }
`;

export const DateBox = styled(DatePicker)`
  border: 1px solid #ddd;
  width: 95px;
  padding: 2px 4px;
  border-radius: 4px;
  color: #333;

  z-index: ${theme.zIndex.z_5};
`;

export const ToggleButton = styled.div`
  width: 100%auto;
  position: absolute;
  right: 14px;
  font-size: 13px;
  top: 33px;
  font-weight: 400;
  color: #555;
  z-index: ${theme.zIndex.z_1};
  padding: 4px 8px;
  background: #0078d4;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
`;
