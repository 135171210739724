import styled from "styled-components";

import theme from "src/styles/theme";

export const ExportTitle = styled.h3`
  margin-top: 20px;
  margin-bottom: 10px;
  color: #666;
  font-weight: 300;
`;

export const ExportWrapper = styled.div`
  background: ${theme.color.white_20};
  width: 100%;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 10px;

  .react-datepicker-wrapper {
    width: 100px !important;
  }
`;

export const ExportButton = styled.button`
  width: auto;
  padding: 0;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 -15px 20px -10px rgba(0, 0, 0, 0.15) inset;
  cursor: pointer;
  color: #1f1f1f;
  padding: 5px;
`;
