import type { ReasonType, TerminatedUserType, TerminatedUserListType } from "src/types/apiType";
/* eslint-disable @typescript-eslint/no-explicit-any */

const dataLabels = {
  billion: {
    enabled: true,
    formatter: function (val: any) {
      const num = val * 0.00000001;
      return num.toFixed(0) + "억";
    },
  },

  million: {
    enabled: true,
    formatter: function (val: any) {
      const num = val * 0.0001;
      if (num > 10000) {
        return (num * 0.0001).toFixed(1) + "억";
      }
      return num.toFixed(0) + "만";
    },
  },
  count: {
    enabled: true,
    formatter: function (val: any) {
      return val.toFixed(0) + "명";
    },
  },
};

const yaxis = {
  billion: {
    labels: {
      maxWidth: 70,
      formatter: (value: any) => {
        const num = value * 0.00000001;
        return num.toFixed(1) + "억";
      },
    },
  },
  million: {
    labels: {
      maxWidth: 70,
      formatter: (value: any) => {
        const num = value * 0.0001;
        if (num > 10000) {
          return (num * 0.0001).toFixed(1) + "억";
        }
        return num.toFixed(0) + "만";
      },
    },
  },
  count: {
    labels: {
      maxWidth: 70,
      formatter: (value: any) => {
        return value.toFixed(0) + "명";
      },
    },
  },
};

const tooltips = {
  amount: {
    y: {
      formatter: function (val: any) {
        return val.toLocaleString() + "원";
      },
    },
  },
  user: {
    y: {
      formatter: function (val: any) {
        return val.toLocaleString() + "명";
      },
    },
  },
};

const terminationUser = (data: TerminatedUserListType, arr: string[], reason: ReasonType) => {
  return arr
    .map(date => {
      const count = data.find((res: TerminatedUserType) => res.date === date && res.reason === reason)?.count || 0;
      return { date, count };
    })
    .map(x => x.count);
};

export { dataLabels, yaxis, tooltips, terminationUser };
