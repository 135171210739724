import { instance } from "./instance";

// login api
export const post_admin_login = async (id: string, pw: string) => {
  const result = await instance({
    method: "post",
    url: `v1/users/dashboard-login/`,
    data: {
      username: id,
      password: pw,
    },
  });

  return result;
};
