import { DepositDetailProps } from "@/types/depositType";
import { useMutation, useQueryClient } from "react-query";
import { instance } from "../instance";

// 특정 날짜에 따른 가게의 입출금 내역을 수정합니다.
const post_deposit_detail = async (store_id: number, body: DepositDetailProps[]) => {
  const { data } = await instance({
    method: "post",
    url: `/v2/admin/deposits/store/${store_id}`,
    data: body,
  });

  return data;
};

export const useAddDepositDetail = (store_id: number) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ["EditDepositDetail", store_id],
    (data: DepositDetailProps[]) => post_deposit_detail(store_id, data),
    {
      onSuccess: () => {
        console.log("onSuccess");
        queryClient.invalidateQueries(["DepositDetail"]);
        queryClient.invalidateQueries(["DepositOverview"]);
      },
      onError: error => {
        console.log("onError", error);
      },
    },
  );

  return mutation;
};
