import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";

import styled from "styled-components";

import { get_cs_all_stores } from "src/apis/admin";

import { ListTable, PageNavigation } from "src/components/block";
import { StoreSearchBar } from "src/components/module/admin";

import type { GetUserInterface, AllStoreInterface } from "src/types/adminType";

export interface StoreColumnsInterface {
  id: number;
  name: string;
  phone?: string;
  title: string;
  managerPhone?: string;
  firstEarlypayDate: string;
  pricePolicy: string;
  serviceType: string;
  serviceStatus: string;
  isCardconnectCompleted: boolean;
  inflowPath: string;
  inflowMedium: string;
  created?: string;
}

const Wrap = styled.div`
  width: 100%;
  min-height: calc(100vh - 100px);
  height: calc(-100px + 100vh);
  margin-top: 100px;
  padding: 12px 100px;

  @media (max-width: 1100px) {
    padding: 12px 30px;
  }

  @media (max-width: 480px) {
    padding: 12px 10px;
  }
`;

const Store = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const urlPage = Number(searchParams.get("page"));
  const keyword = searchParams.get("kw") ?? "";
  const [isKeyword, setIsKeyword] = useState<boolean>(false);

  const [stores, setStores] = useState<AllStoreInterface>();
  const [currentPage, setCurrentPage] = useState<number>(urlPage);

  const pageLimite = 20;
  const storeCount: number = isKeyword ? stores?.count : 0;
  const storeList: StoreColumnsInterface[] = stores?.results.map(store => {
    return {
      id: store.id,
      name: store.user.name,
      title: store.title,
      firstEarlypayDate: store.ServiceInfo.firstEarlypayDate,
      pricePolicy: store.PricePolicy.title,
      serviceType: store.serviceType,
      serviceStatus: store.ServiceInfo.serviceStatus,
      isCardconnectCompleted: store.isCardconnectCompleted,
      inflowPath: store.inflowPath,
      inflowMedium: store.inflowMedium,
    };
  });
  const columns: string[] = [
    "ID",
    "이름",
    "상호명",
    "서비스 이용기간",
    "가격 정책",
    "플랫폼 연동 타입",
    "상태 정보",
    "VAN연동여부",
    "유입 경로",
    "유입 미디움",
  ];

  const getCsAllStores = async (page = 1, search = ""): Promise<void> => {
    const params: GetUserInterface = {
      search: search,
      page: page,
      page_size: pageLimite,
    };

    if (page !== 1 || search.length) {
      setIsKeyword(true);
      const allStores: AllStoreInterface = await get_cs_all_stores(params);
      setStores(allStores);
    } else {
      setIsKeyword(false);
      setStores(null);
    }
  };

  const pageClickHandler = async (page: number): Promise<void> => {
    await getCsAllStores(page);
    setCurrentPage(page);

    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set("page", `${page}`);

    const newSearchString = newSearchParams.toString();
    const newURL = `${location.pathname}?${newSearchString}`;

    window.history.pushState(null, "", newURL);
  };

  const idClickHandler = (id: number) => {
    navigate(`/admin/earlypay?store=${id}&page=1`);
  };

  useEffect(() => {
    getCsAllStores(urlPage, decodeURIComponent(keyword));
    setCurrentPage(urlPage);
  }, [urlPage, keyword]);

  return (
    <Wrap>
      <StoreSearchBar _storeCount={storeCount} />
      <ListTable _columns={[...columns]} _storeList={storeList} _idClickHandler={idClickHandler} />
      <PageNavigation
        _limite={pageLimite}
        _searchCount={storeCount}
        _currentPage={currentPage}
        _setCurrentPage={pageClickHandler}
      />
    </Wrap>
  );
};

export default Store;
