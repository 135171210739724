import Text from "@/components/elements/Text";
import { useDepositModal } from "@/hooks/useDepositModal";
import { DepositDetailProps } from "@/types/depositType";
import { changeNumberToMoney } from "@/utils/changeNumberToMoney";
import { getDate, getDateTime } from "@/utils/getFormattedDate";
import { Add as AddIcon, Edit as EditIcon, TrashCan as TrashCanIcon } from "@carbon/icons-react";
import { Button, TableBody, TableHead, TableRow } from "@carbon/react";
import React from "react";
import { AddDepositModal } from "../DepositModal";
import { DeleteDepositModal } from "../DepositModal/DeleteDepositModal";
import { EditDepositModal } from "../DepositModal/EditDepositModal";
import {
  CustomTableCell,
  CustomTableHeader,
  DepositTable,
  EditTableCell,
  TableWrapper,
  TitleWrapper,
  Wrapper,
} from "./index.styled";

export interface DepositDetailListProps {
  items: DepositDetailProps[];
  selectedDepositDate: string;
}

export const DepositDetailList = ({ items, selectedDepositDate }: DepositDetailListProps) => {
  const {
    openEditDepositModal,
    openAddDepositModal,
    openDeleteDepositModal,
    closeAddDepositModal,
    closeEditDepositModal,
    closeDeleteDepositModal,
    selectedDepositDetail,
    isVisibleEditDepositModal,
    isVisibleAddDepositModal,
    isVisibleDeleteDepositModal,
  } = useDepositModal();

  return (
    <Wrapper>
      {/** 입출금 내역 추가 모달 */}
      {isVisibleAddDepositModal && (
        <AddDepositModal isVisible={isVisibleAddDepositModal} onClose={closeAddDepositModal} />
      )}

      {/** 입출금 내역 수정 모달 */}
      {isVisibleEditDepositModal && (
        <EditDepositModal
          isVisible={isVisibleEditDepositModal}
          onClose={closeEditDepositModal}
          selectedValues={selectedDepositDetail}
        />
      )}

      {/** 입출금 내역 삭제 모달 */}
      {isVisibleDeleteDepositModal && (
        <DeleteDepositModal
          isVisible={isVisibleDeleteDepositModal}
          onClose={closeDeleteDepositModal}
          selectedValues={selectedDepositDetail}
        />
      )}

      <TitleWrapper>
        <Text size={14} bold>
          입출금 상세내역
        </Text>
        <Button
          size="sm"
          onClick={openAddDepositModal}
          iconDescription="add"
          renderIcon={AddIcon}
          kind="tertiary"
          disabled={selectedDepositDate !== getDate("now", "yyyy-mm-dd")}
        >
          입출금내역 추가
        </Button>
      </TitleWrapper>

      <TableWrapper>
        <DepositTable size="md" aria-label="deposit detail table" stickyHeader={true}>
          <TableHead>
            {["입금사", "입금일시", "입금액", "거래내역 타입", "편집"].map((label, index) => (
              <CustomTableHeader key={index}>{label}</CustomTableHeader>
            ))}
          </TableHead>
          <TableBody>
            {items?.map((item, index) => {
              return (
                <TableRow key={index}>
                  {/** 입금사 Row */}
                  <CustomTableCell>{item.cardCompany}</CustomTableCell>

                  {/** 입금 일시 Row */}
                  <CustomTableCell>{getDateTime(item.depositedAt)}</CustomTableCell>

                  {/** 입금액 Row */}
                  <CustomTableCell>{changeNumberToMoney(item.amountDeposit)}</CustomTableCell>

                  {/** 거래내역 타입 Row */}
                  <CustomTableCell>{item.trxType}</CustomTableCell>

                  {/** 수정 or 삭제 버튼 Row */}
                  <EditTableCell>
                    <Button
                      size="md"
                      onClick={() => openEditDepositModal(item)}
                      iconDescription="edit"
                      renderIcon={EditIcon}
                      hasIconOnly
                      kind="tertiary"
                      disabled={!item.depositedAt.includes(getDate("now", "yyyy-mm-dd"))}
                    />
                    <Button
                      size="md"
                      onClick={() => openDeleteDepositModal(item)}
                      iconDescription="delete"
                      renderIcon={TrashCanIcon}
                      hasIconOnly
                      kind="danger"
                      disabled={!item.depositedAt.includes(getDate("now", "yyyy-mm-dd"))}
                    />
                  </EditTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </DepositTable>
      </TableWrapper>
    </Wrapper>
  );
};

export default DepositDetailList;
