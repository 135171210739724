import * as yup from "yup";

export const depositDetailSchema = yup
  .object({
    cardCompany: yup.string().required(),
    depositedAt: yup.string().required(),
    trxType: yup.string().required(),
    amountDeposit: yup
      .number()
      .positive("입출금액은 0원 이상이어야 합니다.")
      .integer("입출금액은 0원 이상이어야 합니다.")
      .required(),
  })
  .required("항목을 입력해 주세요");
