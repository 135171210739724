import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Outlet } from "react-router-dom";

import { Header } from "src/components/block";

import { getCookie } from "@/utils/cookie";
import { saveToken } from "@/utils/token";
import { LayoutWrapper } from "./index.styled";

const MainLayout = () => {
  const navigate = useNavigate();

  const userToken = getCookie("ep_tk");

  useEffect(() => {
    if (!userToken) {
      return navigate("/login"); // useNavigate hook 은 useEffect안에서만 마운트 실행을 할수있음
    }
  }, [userToken]);

  if (userToken) {
    saveToken(userToken);
  } else {
    return null;
  }

  const startChartDate = {
    start: new Date(),
    end: new Date(),
  };

  return (
    <LayoutWrapper>
      <Header />
      <Outlet context={{ startChartDate }} />
    </LayoutWrapper>
  );
};

export default MainLayout;
