/* eslint-disable @typescript-eslint/no-explicit-any */
import { sub } from "date-fns";
import { ko } from "date-fns/esm/locale";
import React, { useEffect, useState } from "react";

import { get_api_call_count } from "src/apis/chart";

import { FlexBox } from "@/components/atoms";
import { BarChart, ChartCard } from "src/components/block";
import { DateBox, SelectBox, ToggleButton } from "./index.styled";

import { formatingDate } from "@/utils/date";
import Text from "@/components/elements/Text";

const ApiCountBox = () => {
  const IP = import.meta.env.VITE_EARLYPAY_ENV && import.meta.env.VITE_EARLYPAY_BASE_URI;
  const [api, setApi] = useState<any>({
    totalCallCount: 0,
    totalApiCount: 0,
    label: [],
    series: [],
  });
  const [date, setDate] = useState(sub(new Date(), { days: 1 }));
  const [apiCount, setApiCount] = useState(0);
  const [toggle, setToggle] = useState(false);

  const setApiChart = (obj: object, key: string) => {
    const list: any = [];

    Object.entries(obj).map(x => {
      if (x[0] === "0") return;
      list.push(x);
    });
    list.sort((a: any, b: any) => Number(b[1]) - Number(a[1]));

    const label: any = [];
    const series: any = {
      name: key,
      data: [],
    };

    list.map((x: any) => {
      series.data.push(x[1]);

      if (x[0].split("-")[0] === "None(None)") {
        label.push(`Store(${x[0].split("-")[1]})`);
      } else {
        label.push(x[0].split("-")[0]);
      }
    });

    return { label, series };
  };

  const getShortApiCallCount = async (key: string) => {
    const { data } = await get_api_call_count();
    const { label, series } = setApiChart(data.result[key], key);
    setApiCount(series.data.length);

    const shortSeries = {
      name: series.name,
      data: [...series.data.slice(0, 5)],
    };
    setToggle(false);

    setApi({
      label: [...label],
      series: [{ ...shortSeries }],
      totalCallCount: data.totalCallCount,
      totalNewUser: data.result.newUser,
    });
  };

  const getFullApiCallCount = async (key: string) => {
    const { data } = await get_api_call_count();
    const { label, series } = setApiChart(data.result[key], key);
    setApiCount(series.data.length);

    setApi({
      label: [...label],
      series: [{ ...series }],
      totalCallCount: data.totalCallCount,
      totalNewUser: data.result.newUser,
    });
  };

  useEffect(() => {
    getShortApiCallCount("home");
  }, []);

  const yesterDay = formatingDate(date);

  const [dropDown, setDropDown] = useState([
    { key: "홈 페이지", act: true, name: "home" },
    { key: "캘린더", act: false, name: "calendar" },
    { key: "정산내역", act: false, name: "detail" },
  ]);

  const trueKey = dropDown.filter(x => {
    if (x.act === true) return x;
  })[0].name;

  const chageChart = async (arr: { key: string; act: boolean; name: string }[]) => {
    setDropDown([...arr]);
    const trueKey = arr.filter(x => {
      if (x.act === true) return x;
    });
    getShortApiCallCount(trueKey[0].name);
  };

  const toggleChart = () => {
    if (toggle) {
      getShortApiCallCount(trueKey);
    } else {
      getFullApiCallCount(trueKey);
    }
    setToggle(!toggle);
  };

  return (
    <ChartCard
      _dropDownArr={dropDown}
      _onClick={chageChart}
      _dropdown={true}
      _date={false}
      _title={`${formatingDate(new Date())} 페이지별 유저 API 총 카운트`}
    >
      <FlexBox _row _style={{ justifyContent: "space-between", marginBottom: "10px" }}>
        <FlexBox _style={{ width: "auto" }}>
          <div>총 API 콜 카운트 : {api.totalCallCount} </div> <div>총 신규 유저 카운트 : {api.totalNewUser} </div>
          <div>
            {trueKey} 호출 유저수 : {apiCount}{" "}
          </div>
        </FlexBox>
        <div>
          <SelectBox _row _align>
            <div>날짜선택</div>
            <DateBox
              onChange={(date: any) => {
                setDate(date);
              }}
              selectsEnd
              startDate={new Date()}
              minDate={new Date("2023-03-30")}
              selected={date}
              dateFormat="yyyy.MM.dd"
              locale={ko}
              maxDate={sub(new Date(), { days: 1 })}
            />
          </SelectBox>
          <FlexBox _row _style={{ justifyContent: "space-betwenn" }}>
            <a href={`${IP}stats/excel-files/?filename=api-call-${yesterDay}`}>{yesterDay} 파일 엑셀 다운로드</a>
          </FlexBox>
        </div>
      </FlexBox>

      <Text size={16} bold>
        얼리페이 BE API total call count
      </Text>
      <div style={{ position: "relative" }}>
        <ToggleButton onClick={toggleChart}>{toggle ? "접어보기" : "전체보기"}</ToggleButton>
        <BarChart _labels={api.label} _series={api.series} _column={false} _height={"300px"} />
      </div>
    </ChartCard>
  );
};

export default ApiCountBox;
