import { depositOverviewState } from "@/recoil/deposit/atoms";
import { useQuery } from "react-query";
import { useSetRecoilState } from "recoil";
import { instance } from "../instance";

// 해당 가게의 정산 통계를 조회합니다.
const get_deposit_overview = async (store_id: number, start_date?: string, end_date?: string) => {
  const params = { store_id, start_date, end_date };

  const { data } = await instance({
    method: "get",
    url: `/v2/admin/stores/${store_id}/deposit-overview`,
    params: params,
  });

  return data;
};

export const useGetDepositOverview = (store_id: number, start_date?: string, end_date?: string) => {
  const setDepositOverview = useSetRecoilState(depositOverviewState);

  return useQuery(
    ["DepositOverview", store_id, start_date, end_date],
    () => get_deposit_overview(store_id, start_date, end_date),
    {
      enabled: !!store_id,
      onSuccess: data => setDepositOverview(data.results),
    },
  );
};
