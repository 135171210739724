import type { UtmType } from "src/types/apiType";
import { instance } from "./instance";

// 일일 선정산 요약정보를 불러옵니다
export const get_users_statistics = async (type = "all") => {
  const params = { type: type };

  const result = await instance({
    method: "get",
    url: `/stats/users/statistics/`,
    params: params,
  });

  return result;
};

// 일일 선정산 요약정보를 불러옵니다
export const get_user_tracking = async (user_id: string, start_date: string, end_date: string) => {
  const params = { user_id, start_date, end_date };

  const result = await instance({
    method: "get",
    url: `/stats/loki/user-tracking/`,
    params: params,
  });

  return result;
};

// 일정 기간의 UTM 유저를 불러옵니다.
export const get_utm_funnel = async (start_date: string, end_date: string, type: string): Promise<UtmType[]> => {
  const params = { start_date, end_date, type };

  const { data } = await instance({
    method: "get",
    url: `/stats/users/utm/`,
    params: params,
  });

  return data;
};

// 일정 기간의 UTM 유저를 불러옵니다.
export const get_old_utm_funnel = async (start_date: string, end_date: string) => {
  const params = { start_date, end_date };

  const result = await instance({
    method: "get",
    url: `/stats/users/old-utm/`,
    params: params,
  });

  return result;
};

// 얼리페이 선정산관련 정보를 불러옵니다.
export const get_earlypay = async () => {
  const result = await instance({
    method: "get",
    url: `/stats/earlypay/accumulate/`,
  });

  return result;
};

// 얼리페이 선정산관련 정보를 불러옵니다.
export const get_earlypay_detail = async (type: string, start_date: string, end_date: string) => {
  const params = { type, start_date, end_date };
  const result = await instance({
    method: "get",
    url: `/stats/earlypay/`,
    params: params,
  });

  return result;
};

// 얼리페이 해지인원수를 불러옵니다
export const get_users_termination = async (type: string, start_date: string, end_date: string) => {
  const params = { start_date, end_date, type };
  const result = await instance({
    method: "get",
    url: `/stats/users/termination/`,
    params: params,
  });

  return result;
};

// 얼리페이 api call count
export const get_api_call_count = async () => {
  const result = await instance({
    method: "get",
    url: `/stats/users/api-call/`,
  });

  return result;
};

// 얼리페이 api call count 엑셀파일 가져오기
export const get_api_call_count_excel_files = async (date: string) => {
  await instance({
    method: "get",
    url: `/stats/excel-files/?filename=${date}`,
  });
};

// 코호트
export const get_users_cohort = async (startDate: string, endDate: string) => {
  const params = { start_date: startDate, end_date: endDate };

  const reuslt = await instance({
    method: "get",
    url: `/stats/users/cohort/`,
    params: params,
  });

  return reuslt;
};
