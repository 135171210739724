import React, { useEffect, useState } from "react";

import { Accordion } from "@/components/atoms";
import { CheckBox, HeadingCard } from "src/components/block";

import { get_all_sales } from "src/apis/admin";

import theme from "src/styles/theme";
import { RawData, SaleBadeg, SaleCard, TotalDeposit } from "./index.styled";

import type { DetailPropsInterface } from "src/pages/Admin/Detail";
import type { PlatformSaleInterface } from "src/types/adminType";

interface PlatformInterface extends DetailPropsInterface {
  _setCardList: (list: string[]) => void;
}

const PlatformSale = ({ _storeId, _date, _setCardList }: PlatformInterface) => {
  const [sales, setSales] = useState<PlatformSaleInterface[]>(null);
  const [salesList, setSalesList] = useState<PlatformSaleInterface[]>([]);
  const [checkBox, setCheckBox] = useState<{ key: string; act: boolean }[]>([{ key: "", act: false }]);

  const storeId: number = _storeId;
  const date = new Date(_date);
  const totalSale = salesList?.map(sale => sale.amountSale).reduce((prev, cur) => prev + cur, 0);
  const totalSaleFee = salesList?.map(sale => sale.amountFee).reduce((prev, cur) => prev + cur, 0);
  const totalCeoFee = salesList?.map(sale => sale.ceoDc).reduce((prev, cur) => prev + cur, 0);
  const totalDeliveryFee = salesList?.map(sale => sale.deliveryAgentFee).reduce((prev, cur) => prev + cur, 0);
  const totalDeposit = salesList?.map(sale => sale.amountDeposit).reduce((prev, cur) => prev + cur, 0);
  const totalFee = totalSaleFee + totalCeoFee + totalDeliveryFee;
  date.setDate(date.getDate() - 1);

  const getPlatformSales = async () => {
    const params = {
      sold_at: date.toISOString().split("T")[0],
    };
    const platformSales: PlatformSaleInterface[] = await get_all_sales(params, storeId);

    const platformList = deduplicationPlatform(platformSales).map(platform => ({ key: platform, act: false }));

    const activeCardList = platformSales
      .filter(sale => {
        if (sale.cardCompany !== "알 수 없음" && sale.numApproval !== null) return sale;
      })
      .map(sale => sale.numApproval);

    _setCardList(activeCardList);
    setCheckBox([...platformList, { key: "전체", act: false }]);
    setSales(platformSales);
    setSalesList(platformSales);
  };

  const deduplicationPlatform = (platformSales: PlatformSaleInterface[]): string[] => {
    const platformList = new Set([].concat(...platformSales.map(sale => [sale.cardCompany, sale.platform])));

    return [...platformList];
  };

  const platformFilterHandler = (checkBox: { key: string; act: boolean }[]) => {
    const activeList = checkBox
      .filter(check => {
        if (check.act === false) return check;
      })
      .map(item => item.key);
    const filerList = sales.filter(sale => {
      if (activeList.includes(sale.platform) || activeList.includes(sale.cardCompany)) return sale;
    });

    setCheckBox(checkBox);
    setSalesList(filerList);
  };

  useEffect(() => {
    getPlatformSales();
  }, []);

  return (
    <div>
      <HeadingCard _title={`플랫폼 매출 내역 (${salesList.length}개)`}>
        <CheckBox _checkList={checkBox} _setCheckList={platformFilterHandler} />
        {salesList.length > 0 && (
          <TotalDeposit>
            <div>총 매출 : {totalSale.toLocaleString()}원</div>
            <div>총 수수료 : -{totalFee.toLocaleString()}원</div>
            <div>총 정산 : {totalDeposit.toLocaleString()}원</div>
          </TotalDeposit>
        )}
        {salesList.length === 0 && <div>플랫폼 매출 내역이 없습니다.</div>}
        <div>
          {salesList.map((sale, index) => {
            return <Sale key={index} _sale={sale} _index={index} />;
          })}
        </div>
      </HeadingCard>
    </div>
  );
};

export default PlatformSale;

interface SalseInterface {
  _sale: PlatformSaleInterface;
  _index: number;
}

const Sale = ({ _sale, _index }: SalseInterface) => {
  const raw = JSON.stringify(_sale.rawData, null, 4);
  const meetCard = _sale.rawData.paymentMethod === "만나서 카드결제" || _sale.rawData.payMet === "신용카드";
  const meetCash = _sale.rawData.paymentMethod === "만나서 현금결제" || _sale.rawData.payMet === "현금";

  return (
    <Accordion key={_index}>
      <SaleCard _index={_index}>
        <article>
          <div>
            {_sale.isExtraPay && <SaleBadeg _bg={theme.color.green_00}>EXTRA</SaleBadeg>}
            {_sale.isCanceled && <SaleBadeg _bg={theme.color.red_00}>CANCEL</SaleBadeg>}
            {meetCard && <SaleBadeg _bg={theme.color.blue_00}>M.CARD</SaleBadeg>}
            {meetCash && <SaleBadeg _bg="#d79600">M.CASH</SaleBadeg>} {_sale.platform}({_sale.numApproval})
          </div>
          <div>매출: {_sale.amountSale.toLocaleString()}원</div>
        </article>
        <article>
          <div>사장님자체할인: {_sale.ceoDc.toLocaleString()}원</div>
          <div>배달대행료: {_sale.deliveryAgentFee.toLocaleString()}원</div>
        </article>
        <article>
          <div>수수료: {_sale.amountFee.toLocaleString()}원</div>
          <div>정산금: {_sale.amountDeposit.toLocaleString()}원</div>
        </article>
        <article>
          <div>매출발생일: {_sale.soldAt.split("T")[0]}</div> <div>회수예정일: {_sale.willDepositedAt}</div>
        </article>
        <article>
          <div>카드사: {_sale.cardCompany}</div>
          <div>
            선정산 포함여부:{" "}
            {_sale.isEarlypay ? (
              <img src="https://earlypay-backend.s3.amazonaws.com/static/admin/img/icon-yes.svg" />
            ) : (
              <img src="https://earlypay-backend.s3.amazonaws.com/static/admin/img/icon-no.svg" />
            )}
          </div>
        </article>
      </SaleCard>
      <RawData>{raw}</RawData>
    </Accordion>
  );
};
