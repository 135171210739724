export const HEADER = ["입금사", "입금일시", "거래내역 타입", "입출금액", ""];
export const DEPOSIT_COMPANY_LIST = [
  "얼리페이",
  "배달의민족",
  "요기요",
  "쿠팡이츠",
  "삼성",
  "우리",
  "국민",
  "비씨",
  "하나",
  "롯데",
  "현대",
  "농협",
  "신한",
  "기타",
];
export const DEPOSIT_TYPE_LIST = ["입금", "출금"];
