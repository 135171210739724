import theme from "src/styles/theme";
import styled from "styled-components";

export const PageNavigationWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  padding: 10px;
  flex-wrap: wrap;
  justify-content: center;
`;

export const NavigationBar = styled.div`
  width: auto;
  display: flex;
  background: #efefef;
  border-radius: 20px;
  padding: 2px 4px;
  gap: 4px;
  width: 240px;
  justify-content: center;
`;

export const NavigationButton = styled.button`
  width: 26px;
  height: 26px;
  text-decoration: none;
  font-size: 15px;
  text-align: center;
  border-radius: 5px;
  color: ${theme.color.gray_30};
  font-weight: 500;
  cursor: pointer;
  background: transparent;
  animation-fill-mode: forwards;
  box-shadow: ${(props: { _currentPage: boolean }) => props._currentPage && `0px 0px 5px 1px ${theme.color.blue_20}`};
  color: ${(props: { _currentPage: boolean }) => props._currentPage && theme.color.white_00};
  background: ${(props: { _currentPage: boolean }) => props._currentPage && theme.color.blue_20};
  cursor: ${(props: { _currentPage: boolean }) => props._currentPage && "default"};
  transform: ${(props: { _currentPage: boolean }) => props._currentPage && "scale(0.9);"};
  animation: ${(props: { _currentPage: boolean }) => props._currentPage && "showButton 150ms linear"};

  @keyframes showButton {
    to {
      transform: scale(1);
    }
  }

  &:hover {
    transition: all 0.15s;
    color: ${(props: { _currentPage: boolean }) => !props._currentPage && theme.color.blue_20};
  }

  @media (max-width: 425px) {
    width: 28px;
    height: 28px;
  }
`;

export const HnadlerButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  font-size: 14px;
  color: ${theme.color.gray_30};
  transition: all 0.15s;

  &:hover {
    transition: all 0.15s;
    color: ${theme.color.white_00};
    background: ${theme.color.blue_20};
  }
`;

export const Dots = styled.div`
  color: ${theme.color.gray_30};
`;
