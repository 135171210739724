import * as Sentry from "@sentry/react";
import axios from "axios";

const API_BASE_URL = import.meta.env.VITE_EARLYPAY_BASE_URI;

const instance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 8000,
  headers: {
    authorization: ``,
    "content-type": "application/json;charset=UTF-8",
    accept: "application/json",
  },
});

instance.interceptors.request.use(
  config => {
    Sentry.setContext("API Request Detail", config);

    return config;
  },
  error => {
    console.log("interceptor > error", error);
    console.log(error);
  },
);

// axios response interceptors
instance.interceptors.response.use(
  response => {
    Sentry.setContext("API Response Detail", response);
    return response;
  },
  error => {
    console.log("interceptor > error", error);
    return Promise.reject(error);
  },
);

export { instance };
