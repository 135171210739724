import { Table, TableContainer } from "@carbon/react";
import styled from "styled-components";
import Text from "@/components/elements/Text";
import { themes } from "@carbon/themes";

export const Wrapper = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TableWrapper = styled(TableContainer)`
  height: 100%;

  .cds--data-table_inner-container {
    height: 100%;
    background-color: white;
  }

  .cds--data-table-content {
    height: 100%;
  }
`;

export const StoreTable = styled(Table)`
  height: 100%;
  min-height: 100%;
`;

export const LinkText = styled(Text)`
  color: ${themes.white.linkPrimary};
  text-decoration: underline;
  cursor: pointer;
`;
