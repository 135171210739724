import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 2px;
`;

export const CopyButton = styled.button`
  cursor: pointer;
  background-color: transparent;
`;
