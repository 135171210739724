import React, { createContext, useState } from "react";

interface MyContextInterface {
  storePageCount: number;
  setStorePageCount: (count: number) => void;
  storeKeyword: string;
  setStoreKeyword: (keyword: string) => void;
  earlypayPageCount: number;
  setEarlypayPageCount: (count: number) => void;
  earlypayKeyword: string;
  setEarlypayKeyword: (keyword: string) => void;
  user: string;
  setUser: (user: string) => void;
}

interface PropsInterface {
  children: React.ReactNode;
}

export const AdminContext = createContext<MyContextInterface | null>(null);

const AdminProvider = ({ children }: PropsInterface) => {
  const [storePageCount, setStorePageCount] = useState<number>(1);
  const [storeKeyword, setStoreKeyword] = useState<string>("");
  const [earlypayPageCount, setEarlypayPageCount] = useState<number>(1);
  const [earlypayKeyword, setEarlypayKeyword] = useState<string>("");
  const [user, setUser] = useState<string>("");

  return (
    <AdminContext.Provider
      value={{
        storePageCount,
        setStorePageCount,
        storeKeyword,
        setStoreKeyword,
        earlypayPageCount,
        setEarlypayPageCount,
        earlypayKeyword,
        setEarlypayKeyword,
        user,
        setUser,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
