import { get_store_list } from "@/apis/stores/getStoreList";
import Text from "@/components/elements/Text";
import SearchStoreBox from "@/components/features/settlement/SearchStoreBox";
import { Breadcrumb, BreadcrumbItem, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@carbon/react";
import { themes } from "@carbon/themes";
import React from "react";
import { useLocation } from "react-router";
import { LinkText, StoreTable, TableWrapper, Wrapper } from "./index.styled";
import { useInfiniteQuery } from "react-query";
import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";
import { useSearchStore } from "@/hooks/useSearchStore";

const Store: React.FC = () => {
  const location = useLocation();
  const headers = ["가게이름", "고객이름", "사업자등록번호"];
  const searchParams = new URLSearchParams(location.search);
  const searchKeyword = searchParams.get("search");
  const { handleGoToDetail } = useSearchStore();

  /** 무한 스크롤 구현을 통해 데이터를 일정 개수씩 가져오는 함수 */
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ["storeList", searchKeyword],
    ({ pageParam = 1 }) => get_store_list(pageParam, 20, searchKeyword),
    {
      select: data => ({
        pages: searchKeyword ? data.pages.flatMap(list => list?.results) : undefined,
        pageParams: searchKeyword ? data?.pageParams : undefined,
      }),
      getNextPageParam: lastPage => {
        // 마지막 페이지일 경우 페이지 업데이트 막기
        const nextPage = lastPage?.nextPage;
        return nextPage ? nextPage : undefined;
      },
    },
  );

  /** target observer 요소를 감지하여 storeList 의 다음 페이지 업데이트 */
  const { setTarget } = useInfiniteScroll({
    hasNextPage,
    fetchNextPage,
  });

  return (
    <Wrapper>
      <Breadcrumb noTrailingSlash={true}>
        <BreadcrumbItem href="#">정산 조정</BreadcrumbItem>
      </Breadcrumb>
      <Text size={24} bold>
        정산 조정
      </Text>

      {/** 정산 조정 가게 검색 */}
      <Text size={12} color={themes.white.textSecondary}>
        가게 검색
      </Text>
      <SearchStoreBox placeholder="가게이름, 고객이름, 사업자로 검색" hasSearchButton />

      {/** 정산 가게 목록 */}
      <TableWrapper>
        <StoreTable aria-label="store table" stickyHeader={true} size="md" useZebraStyles={true}>
          <TableHead>
            <TableRow>
              {headers.map((header: string, index: number) => (
                <TableHeader key={index}>{header}</TableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.pages?.map((props, index: number) => {
              const storeName = props?.title;
              const userName = props.user?.name;
              const businessNumber = props.businessRegistrationNumber?.replace(/^(\d{3})(\d{2})(\d{5})$/, "$1-$2-$3");

              return (
                <TableRow key={index}>
                  <TableCell>
                    <LinkText onClick={() => handleGoToDetail(props.id, storeName)}>
                      {storeName ? storeName : "-"}
                    </LinkText>
                  </TableCell>
                  <TableCell>{userName ? userName : "-"}</TableCell>
                  <TableCell>{businessNumber ? businessNumber : "-"}</TableCell>
                </TableRow>
              );
            })}
            {data?.pages && <div ref={setTarget} style={{ height: 1, width: "100%" }} />}
          </TableBody>
        </StoreTable>
      </TableWrapper>
    </Wrapper>
  );
};

export default Store;
