import { atom } from "recoil";

/** 정산 통계 조회 데이터 */
export const depositOverviewState = atom({
  key: "DepositOverview",
  default: {},
});

/** 가게 입금 내역 조회 */
export const depositDetailState = atom({
  key: "DepositDetail",
  default: [],
});

/** 가게 입금 내역 조회 날짜 */
export const selectedDepositDateState = atom({
  key: "DepositSelectedDate",
  default: "",
});
