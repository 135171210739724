const theme = {
  color: {
    // red
    red_00: "#E82639",

    // blue
    blue_00: "#0078d4",
    blue_10: "#253aa9",
    blue_20: "#48a0e4",

    // white
    white_00: "#ffffff",
    white_10: "#fcfcfc",
    white_20: "#f9f9f9",
    white_30: "#F7F7F7",

    // gray
    gray_00: "#E8E8E8",
    gray_10: "#CFD3D6",
    gray_20: "#A9A9A9",
    gray_30: "#9FA6AF",
    gray_40: "#767B83",
    gray_50: "#555555",
    gray_60: "#56585D",

    // black
    black_00: "#1f1f1f",
    black_10: "#1F1F1F",
    black_20: "#333436",

    // green
    green_00: "#33c514",
  },

  lineHight: {
    12: "18px",
    13: "20px",
    14: "22px",
    15: "22px",
    16: "22px",
    17: "28px",
    18: "24px",
    20: "30px",
    22: "28px",
    24: "36px",
    25: "34px",
    28: "36px",
    30: "38px",
    32: "40px",
  },

  zIndex: {
    z_1: "100",
    z_2: "200",
    z_3: "300",
    z_4: "400",
    z_5: "500",
  },

  fontFamily: [
    { class: "icons", fontFamily: "material-symbols-outlined" },
    { class: "poppins", fontFamily: "poppins-regular" },
    { class: "roboto", fontFamily: "roboto-regular" },
  ],
};

export default theme;
