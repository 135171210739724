import { useGetStoreList } from "@/apis/stores/getStoreList";
import useDebounce from "@/hooks/useDebounce";
import { useSearchStore } from "@/hooks/useSearchStore";
import { Search as SearchIcon } from "@carbon/icons-react";
import { Button } from "@carbon/react";
import React, { useEffect } from "react";
import { CustomSearch, SearchItem, SearchListWrapper, SearchStoreBoxWrapper, SearchWrapper } from "./index.styled";
import { useLocation } from "react-router";
import { StoreProps } from "@/types/storeType";

export interface SearchStoreBoxProps {
  placeholder?: string;
  size?: "lg" | "sm" | "md";
  hasSearchButton?: boolean;
}

export const SearchStoreBox = ({ size, placeholder, hasSearchButton }: SearchStoreBoxProps) => {
  const {
    selectedItemIndex,
    searchKeyword,
    setSearchKeyword,
    handleClickSearchStoreButton,
    handleGoToDetail,
    handleFocus,
    handleKeyDown,
    handleChange,
    isOpenSearchList,
  } = useSearchStore();
  const debouncedSearch = useDebounce(searchKeyword, 300);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchParams = params.get("search");

  const { data } = useGetStoreList(1, 5, debouncedSearch);

  useEffect(() => {
    if (searchParams) {
      setSearchKeyword(searchParams);
    }
  }, [location.pathname]);

  return (
    <SearchStoreBoxWrapper>
      <SearchWrapper
        label="List title"
        kind="on-page"
        action={
          <CustomSearch
            size={size ? size : "lg"}
            placeholder={placeholder}
            value={searchKeyword}
            labelText="Search"
            closeButtonLabelText="Clear search input"
            onChange={handleChange}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, data?.results)}
            onFocus={handleFocus}
          />
        }
      >
        <SearchListWrapper>
          {isOpenSearchList &&
            data?.results?.map((items: StoreProps, index: number) => {
              const name = items.user.name ? items.user.name : "-";
              const store = items.title ? items.title : "-";

              return (
                <SearchItem
                  key={index}
                  onClick={() => handleGoToDetail(items.id, items.title)}
                  isSelected={index === selectedItemIndex}
                >{`${store}(${name})`}</SearchItem>
              );
            })}
        </SearchListWrapper>
      </SearchWrapper>

      {hasSearchButton && (
        <Button
          as="button"
          kind="primary"
          renderIcon={SearchIcon}
          iconDescription="가게 검색 버튼"
          onClick={handleClickSearchStoreButton}
          type="button"
        >
          찾기
        </Button>
      )}
    </SearchStoreBoxWrapper>
  );
};

export default SearchStoreBox;
