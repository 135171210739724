import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";

import "react-toastify/dist/ReactToastify.css";

import { AdminDetail, AdminStore, Earlypay, Funnel, Home, Login, SettlementDetail, SettlementStore } from "@/pages";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import AdminProvider from "src/pages/Admin/adminContext";
import { initializeServices } from "./init";
import SettlementLayout from "./layouts/SettlementLayout";
import MainLayout from "./layouts/MainLayout";
import Toast from "@/components/elements/Toast";

function App() {
  initializeServices();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <RecoilRoot>
        <Toast />
        <Router>
          <Routes>
            <Route path="" element={<MainLayout />}>
              <Route path="" element={<Home />} />
              <Route path="funnel" element={<Funnel />} />
              <Route path="admin">
                <Route
                  path="store"
                  element={
                    <AdminProvider>
                      <AdminStore />
                    </AdminProvider>
                  }
                />
                <Route
                  path="earlypay"
                  element={
                    <AdminProvider>
                      <Earlypay />
                    </AdminProvider>
                  }
                />
                <Route
                  path="detail"
                  element={
                    <AdminProvider>
                      <AdminDetail />
                    </AdminProvider>
                  }
                />
              </Route>
              <Route path="" element={<SettlementLayout />}>
                <Route path="/settlement" element={<SettlementStore />} />
                <Route path="/settlement/detail/:id" element={<SettlementDetail />} />
              </Route>
            </Route>
            <Route path="login" element={<Login />} />
          </Routes>
        </Router>
      </RecoilRoot>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
