import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import styled from "styled-components";

import { get_cs_store_earlypay, get_store_detail } from "src/apis/admin";

import { ListTable, PageNavigation } from "src/components/block";
import { EarlypayDateFilter, Export, StoreSearchBar } from "src/components/module/admin";

import { toISOStringKR } from "@/utils/toISOStringKR";
import type { EarlypayInterface, GetUserInterface } from "src/types/adminType";

const Wrap = styled.div`
  width: 100%;
  min-height: calc(100vh - 100px);
  height: 100%;
  margin-top: 100px;
  padding: 12px 100px;

  @media (max-width: 1100px) {
    padding: 12px 30px;
  }

  @media (max-width: 480px) {
    padding: 12px 10px;
  }
`;

const FilterBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media (max-width: 480px) {
    display: block;
  }
`;

export interface UserColumnsInterface {
  id: number;
  store: string;
  created: string;
  amount: string;
  brn: string;
  amountUnderdepositRepaid: string;
  amountServiceFeeRepaid: string;
  amountInterestRepaid: string;
  isDeposited: string;
  remark: string | null;
}

const Earlypay = () => {
  const navigate = useNavigate();
  const loacation = useLocation();
  const storeId = loacation.search.replace("?", "").split("&")[0].split("=")[1];

  const [earlypays, setEarlypays] = useState<EarlypayInterface>();
  const [storeInfo, setStoreInfo] = useState({ store: "", brn: "" });
  const searchParams = new URLSearchParams(location.search);
  const urlPage = Number(searchParams.get("page"));
  const [currentPage, setCurrentPage] = useState<number>(urlPage);
  const [date, setDate] = useState({
    start: null,
    end: null,
  });

  const pageLimite = 20;
  const userCount: number = earlypays?.count;
  const earlypayList: UserColumnsInterface[] = earlypays?.results.map(earlypay => {
    return {
      id: earlypay.id,
      store: storeInfo.store,
      created: earlypay.created.split("T")[0],
      brn: storeInfo.brn,
      amount: earlypay.amount.toLocaleString(),
      amountUnderdepositRepaid: earlypay.amountUnderdepositRepaid.toLocaleString(),
      amountServiceFeeRepaid: earlypay.amountServiceFeeRepaid.toLocaleString(),
      amountInterestRepaid: earlypay.amountInterestRepaid.toLocaleString(),
      isDeposited: earlypay.isDeposited ? "지급" : "미지급",
      remark: earlypay.remark,
    };
  });
  const columns: string[] = [
    "ID",
    "상호명",
    "날짜",
    "사업자번호",
    "선정산금",
    "미수금 회수금",
    "서비스 이용료 회수금",
    "선정산 이자 회수금",
    "지급여부",
    "비고",
  ];

  const getCsAllEarlypay = async (
    page = 1,
    search = "",
    pageLimite = 20,
    start?: string,
    end?: string,
  ): Promise<void> => {
    const params: GetUserInterface = {
      search: search,
      page: page,
      page_size: pageLimite,
    };

    if (start) params.start_date = start;
    if (end) params.end_date = end;

    const allEarlypay: EarlypayInterface = await get_cs_store_earlypay(params, Number(storeId));

    setEarlypays(allEarlypay);
  };

  const getStoreDetail = async () => {
    const params: GetUserInterface = {
      page: 1,
      search: "",
      page_size: 100,
    };
    const storeInfo = await get_store_detail(params, Number(storeId));

    setStoreInfo({
      store: `${storeInfo.representativeName}(${storeInfo.title})`,
      brn: storeInfo.businessRegistrationNumber,
    });
  };

  const pageClickHandler = async (page: number): Promise<void> => {
    setCurrentPage(page);

    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set("page", `${page}`);

    if (date.start !== null && date.start !== null) {
      return await getCsAllEarlypay(page, "", 20, toISOStringKR(date.start), toISOStringKR(date.end));
    }

    await getCsAllEarlypay(page);

    const newSearchString = newSearchParams.toString();
    const newURL = `${location.pathname}?${newSearchString}`;

    window.history.pushState(null, "", newURL);
  };

  const idClickHandler = (id: number, created: string | false) => {
    navigate(`/admin/detail?earlypay=${id}&store=${storeId}&date=${created}`);
  };

  const dateFilterHandler = async (start: string, end: string): Promise<void> => {
    setCurrentPage(1);
    await getCsAllEarlypay(1, "", pageLimite, start, end);
  };

  useEffect(() => {
    getStoreDetail();
    getCsAllEarlypay(urlPage);
    setCurrentPage(urlPage);
  }, [urlPage]);

  return (
    <Wrap>
      <StoreSearchBar _storeCount={0} />
      <FilterBox>
        <EarlypayDateFilter _onClick={dateFilterHandler} _date={date} _setDate={setDate} />
        <Export _storeId={storeId} />
      </FilterBox>
      <ListTable _columns={[...columns]} _storeList={earlypayList} _idClickHandler={idClickHandler} />
      {earlypayList?.length === 0 && <div style={{ padding: "14px", color: "#777" }}>내역이 없습니다.</div>}
      <PageNavigation
        _limite={pageLimite}
        _searchCount={userCount}
        _currentPage={currentPage}
        _setCurrentPage={pageClickHandler}
      />
    </Wrap>
  );
};

export default Earlypay;
