import React from "react";
import { Container } from "@/components/elements/Toast/index.styled";
import { toast } from "react-toastify";

export const Toast: React.FC = () => {
  return <Container autoClose={2000} position="top-left" hideProgressBar pauseOnHover={false} />;
};
export const showToast = ({ kind, title }: { kind: string; title: string }) => {
  switch (kind) {
    case "info":
      toast.info(title);
      return;
    case "success":
      toast.success(title);
      return;
    case "error":
      toast.error(title);
      return;
    default:
      toast.success(title);
      return;
  }
};

export default Toast;
