import styled from "styled-components";

export const SaleCard = styled.div`
  width: 100%;
  padding: 10px;
  background: ${(props: { _index: number }) => (props._index % 2 === 0 ? "#fff" : "#eee")};
  font-size: 14px;
  color: #222;

  article {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
  }
`;

export const RawData = styled.div`
  padding: 10px;
  border: 1px solid #eee;
  font-size: 15px;
  white-space: pre-wrap;
`;

export const TotalDeposit = styled.div`
  border-radius: 10px;
  border: 1px solid #ccc;
  margin: 10px 0;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const SaleBadeg = styled.span`
  width: 20px;
  height: 10px;
  background: ${(props: { _bg: string }) => props._bg};
  font-size: 10px;
  color: #fff;
  padding: 2px 4px;
`;
