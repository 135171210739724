import styled from "styled-components";

export const UtmWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin: 12px 0;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;

// TODO : 추후 컴포넌트 통합시 제거
export const CheckBox = styled.div`
  display: grid;
  grid-template-columns: ${"1fr ".repeat(7)};
  div {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
  }
`;
