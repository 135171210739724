import { useQuery } from "react-query";
import { instance } from "../instance";

// 해당 가게의 정산 통계를 조회합니다.
const get_sum_expected_payback_date = async (store_id: number, start_date: string, end_date: string) => {
  const params = { store_id, start_date, end_date };

  const { data } = await instance({
    method: "get",
    url: `/v2/admin/platform-sales/stores/${store_id}/total`,
    params: params,
  });

  return data;
};

export const useGetExpectedPaybackDate = (store_id: number, start_date: string, end_date: string) => {
  return useQuery(
    ["ExpectedSumPaybackDate", store_id, start_date, end_date],
    () => get_sum_expected_payback_date(store_id, start_date, end_date),
    {
      enabled: !!store_id,
    },
  );
};
