import CopyIcon from "@/assets/icons/copy-icon.svg?react";
import Text from "@/components/elements/Text";
import React from "react";
import { CopyButton, Wrapper } from "./index.styled";
import { showToast } from "@/components/elements/Toast";

export interface CopiedAccountProps {
  label: string;
  value: string;
  isSecret?: boolean;
  type: string;
}

export const CopiedAccount = ({ type, label, value, isSecret = false }: CopiedAccountProps) => {
  const hiddenText = "********";

  const handleCopyClipBoard = async () => {
    try {
      let text = value;
      if (type === "연락처") text = value.replaceAll("-", "");
      await navigator.clipboard.writeText(text);

      showToast({
        kind: "success",
        title: `${type} ${label} 복사 완료`,
      });
    } catch (e) {
      throw new Error();
    }
  };

  return (
    <Wrapper>
      <Text>{`${label}: ${isSecret ? hiddenText : value}`}</Text>
      <CopyButton type="button" onClick={handleCopyClipBoard}>
        <CopyIcon width={16} height={16} />
      </CopyButton>
    </Wrapper>
  );
};
