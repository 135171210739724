import React, { useState } from "react";
import styled from "styled-components";

import { Button } from "@/components/atoms";
import { get_cfa_sales } from "src/apis/admin";

import { CheckBox, HeadingCard, Spinner } from "src/components/block";
import { SaleBadeg, SaleCard } from "src/components/module/admin/PlatformSale/index.styled";
import theme from "src/styles/theme";

import type { DetailPropsInterface } from "src/pages/Admin/Detail";
import type { CfaSaleInterface } from "src/types/adminType";

const GetCfaButton = styled(Button)`
  background: ${theme.color.blue_00};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
`;

interface CfaInterface extends DetailPropsInterface {
  _cardList: string[];
}

const CfaSale = ({ _storeId, _date, _cardList }: CfaInterface) => {
  const date = new Date(_date);
  date.setDate(date.getDate() - 1);
  const soldAt = date.toISOString().split("T")[0];

  const [cfas, setCfas] = useState<CfaSaleInterface[]>(null);
  const [cfaList, setCfaList] = useState<CfaSaleInterface[]>([]);
  const [checkBox, setCheckBox] = useState<{ key: string; act: boolean }[]>([{ key: "", act: false }]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const cfaCardList = cfas?.map(cfa => {
    let num = String(cfa.approvalNumber);
    if (num.length !== 8) num = "0".repeat(8 - num.length) + num;
    return num;
  });
  const list = cfaCardList?.filter(x => !_cardList.includes(x));

  const getCfaSales = async (storeId: number, date: string) => {
    try {
      const cfaSales = await get_cfa_sales(storeId, date);

      const cardCompanyList = [
        ...new Set(
          cfaSales.map(cfa => {
            return cfa.cardCompany;
          }),
        ),
      ].map((cardCompany: string) => {
        return { key: cardCompany, act: false };
      });

      setCfas(cfaSales);
      setCfaList(cfaSales);
      setCheckBox(cardCompanyList);
    } catch (err) {
      alert(`${err} \n다시 시도해주세요.`);
      throw new Error();
    } finally {
      setIsLoading(true);
    }
  };

  const platformFilterHandler = (checkBox: { key: string; act: boolean }[]) => {
    const activeList = checkBox
      .filter(check => {
        if (check.act === false) return check;
      })
      .map(item => item.key);
    const filerList = cfas?.filter(cfa => {
      if (activeList.includes(cfa.cardCompany)) return cfa;
    });

    setCheckBox(checkBox);
    setCfaList(filerList);
  };

  const getCfaSaleHandler = () => {
    setIsLoading(false);
    getCfaSales(_storeId, soldAt);
  };

  return (
    <HeadingCard _title={`CFA 매출내역 ${cfas !== null ? `(${cfaList.length}건 조회)` : "(조회전)"}`}>
      {cfas === null && isLoading && <GetCfaButton _onClick={getCfaSaleHandler}>CFA 매출확인</GetCfaButton>}
      {!isLoading && <Spinner />}
      {isLoading && cfas !== null && <CheckBox _checkList={checkBox} _setCheckList={platformFilterHandler} />}
      {isLoading && cfas?.length === 0 && <div>매출 내역이 없습니다.</div>}
      {isLoading &&
        cfas !== null &&
        cfaList.map((cfa, index) => {
          return (
            <SaleCard _index={index} key={index}>
              <article>
                <div>
                  {list.includes(String(cfa.approvalNumber)) && <SaleBadeg _bg={"#111"}>NOTIN</SaleBadeg>}{" "}
                  {cfa.cardCompany} ({cfa.approvalNumber})
                </div>
                <div>매출: {cfa.amountSales.toLocaleString()}원</div>
              </article>
              <article>
                <div>
                  {cfa.cardCategory} ({cfa.installment})
                </div>
                <div>
                  승인여부:{" "}
                  {!cfa.isCanceled ? (
                    <img src="https://earlypay-backend.s3.amazonaws.com/static/admin/img/icon-yes.svg" />
                  ) : (
                    <img src="https://earlypay-backend.s3.amazonaws.com/static/admin/img/icon-no.svg" />
                  )}
                </div>
              </article>
              <article>
                <div>매출발생일: {cfa.soldAt.split("T")[0]}</div>
                <div>카드넘버: {cfa.cardNumber}</div>
              </article>
            </SaleCard>
          );
        })}
    </HeadingCard>
  );
};

export default CfaSale;
