import { useGetDepositDetail } from "@/apis/deposit/getDepositDetail";
import { useGetDepositOverview } from "@/apis/deposit/getDepositOverview";
import Text from "@/components/elements/Text";
import { selectedDepositDateState } from "@/recoil/deposit/atoms";
import { StoreDetailProps } from "@/types/storeType";
import { getDate } from "@/utils/getFormattedDate";
import { Close as CloseIcon } from "@carbon/icons-react";
import { Button } from "@carbon/react";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import DepositDetailList from "../DepositDetailList";
import DepositOverviewList from "../DepositOverviewList";
import { CloseStack, CustomModal, DataStack, ModalWrapper } from "./index.styled";
import { useLocation } from "react-router";

export interface DepositDetailModalProps {
  isVisible: boolean;
  storeDetailData: StoreDetailProps;
  onClose: () => void;
}

export const DepositDetailModal = ({ isVisible, onClose, storeDetailData }: DepositDetailModalProps) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [prevLocation, setPrevLocation] = useState(location);
  const selectedDepositDate = useRecoilValue(selectedDepositDateState);
  const { data: depositOverviewData } = useGetDepositOverview(
    storeDetailData.id,
    selectedDepositDate,
    selectedDepositDate,
  );
  const { data: depositDetailData } = useGetDepositDetail(storeDetailData.id, selectedDepositDate);

  useEffect(() => {
    if (prevLocation.pathname !== location.pathname || prevLocation.search !== location.search) {
      onClose();
      setPrevLocation(location);
    }

    const handleKeyPress = event => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.onpopstate = function () {
      onClose();
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [location, prevLocation, searchParams]);

  return (
    <CustomModal isOpen={isVisible} onRequestClose={onClose} ariaHideApp={false}>
      <ModalWrapper>
        {/** 닫기 버튼 */}
        <CloseStack>
          {/** 타이틀 */}
          <Text size={24} bold>
            {`${getDate(selectedDepositDate)} ${storeDetailData.title} / ${storeDetailData.user.name} 정산 조정`}
          </Text>
          <Button
            onClick={onClose}
            tooltipPosition="bottom"
            iconDescription="close"
            renderIcon={CloseIcon}
            hasIconOnly
            kind="ghost"
          />
        </CloseStack>

        <DataStack>
          {/** 입출금 요약 내역 */}
          {depositOverviewData && <DepositOverviewList items={depositOverviewData.results} />}
        </DataStack>

        {/** 입출금 상세내역 */}
        {depositDetailData && (
          <DepositDetailList items={depositDetailData.results} selectedDepositDate={selectedDepositDate} />
        )}
      </ModalWrapper>
    </CustomModal>
  );
};

export default DepositDetailModal;
