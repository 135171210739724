import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
`;

export const ScrollWrapper = styled.div`
  height: 100%;
  overflow: scroll;
`;
