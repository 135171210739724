import Text from "@/components/elements/Text";
import { StoreDetailInfoProps, StoreDetailProps } from "@/types/storeType";
import { changeNumberToMoney } from "@/utils/changeNumberToMoney";
import {
  Add as AddIcon,
  OverflowMenuHorizontal as OverflowMenuIcon,
  Save as SaveIcon,
  Subtract as SubtractIcon,
} from "@carbon/icons-react";
import { OverflowMenuItem, SelectItem } from "@carbon/react";
import React, { useCallback, useEffect, useState } from "react";
import {
  ChangeButton,
  IntegratedSalesWrapper,
  MenuButton,
  SaveButton,
  SelectWrapper,
  StoreInfoBoxWrapper,
  StoreInfoContentsWrapper,
  StoreInfoSummaryWrapper,
  UnderDepositDetailButton,
  UnderDepositStack,
} from "./index.styled";
import { useFieldArray, useForm } from "react-hook-form";
import { useEditStoreDetail } from "@/apis/stores/patchStoreDetail";
import ConnectedPlatformList from "@/components/features/settlement/StoreInfoBox/ConnectedPlatformList";
import UserAccountList from "@/components/features/settlement/StoreInfoBox/UserAccountList";
import { showToast } from "@/components/elements/Toast";
import { useLocation } from "react-router";
import EditExpectedPaybackDateModal from "@/components/features/settlementDetail/EditExpectedPaybackDateModal";
import { useGetExpectedPaybackDate } from "@/apis/deposit/getExpectedPaybackDate";

export interface StoreInfoBoxProps {
  items: StoreDetailProps;
}

const StoreInfoBox: React.FC<StoreInfoBoxProps> = ({ items }) => {
  const location = useLocation();
  const [isVisibleExpectedPaybackDate, setVisibleExpectedPaybackDate] = useState(false);
  const [isSelectedExpand, setIsSelectedExpand] = useState<boolean>(false);
  const vanPlatformList = items.platforms.filter(
    item => item.platform !== "쿠팡이츠" && item.platform !== "요기요" && item.platform !== "배달의민족",
  );
  const deliveryPlatformList = items.platforms.filter(
    item => item.platform === "요기요" || item.platform === "배달의민족" || item.platform === "쿠팡이츠",
  );
  const {
    handleSubmit,
    register,
    control,
    watch,
    reset,
    setValue,
    getValues,
    formState: { isDirty, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      serviceStatus: items.serviceStatus,
      platforms: [...vanPlatformList, ...deliveryPlatformList],
    },
  });
  const { fields } = useFieldArray<StoreDetailInfoProps, "platforms", "id">({
    name: "platforms",
    control: control,
  });
  const serviceStatus = watch("serviceStatus");
  const platforms = watch("platforms");
  const isCloseService = serviceStatus === "해지 진행";
  const isDisabled = (items.serviceStatus === "해지 진행" && isCloseService) || !isDirty;

  const mutation = useEditStoreDetail(items.id);
  const earlypayAdminUrl = import.meta.env.VITE_EARLYPAY_ADMIN_URI;

  const store = items.title;
  const name = items.user.name;
  const businessNumber = items.businessRegistrationNumber?.replace(/^(\d{3})(\d{2})(\d{5})$/, "$1-$2-$3");

  const { data: depositDate } = useGetExpectedPaybackDate(items.id);

  /** 사용자 세부 정보 확장 버튼 이벤트 함수 */
  const onClickExpandButton = () => {
    setIsSelectedExpand(!isSelectedExpand);
  };

  /** 회수예정날짜 수정 모달 선택하였을 경우 */
  const handleClickExpectedPaybackDate = () => {
    setVisibleExpectedPaybackDate(true);
  };

  /** 회수예정날짜 수정 모달 닫기 호출 */
  const closeExpectedPaybackDateModal = () => {
    setVisibleExpectedPaybackDate(false);
  };

  /** 서비스 사용 상태 저장 */
  const onSubmit = useCallback(
    (data: StoreDetailInfoProps) => {
      mutation.mutate(data);
      showToast({
        kind: "info",
        title: "가게정보 업데이트됨",
      });
    },

    [mutation],
  );

  useEffect(() => {
    setValue("serviceStatus", serviceStatus);

    /** 서비스 사용에서 해지 진행을 선택하는 경우, 연동카드사/배달플랫폼 비활성화 */
    if (isCloseService) {
      const newPlatforms = platforms.map(item => ({
        ...item,
        isDisabled: true,
      }));
      setValue("platforms", [...newPlatforms]);
    } else {
      setValue("platforms", [...platforms]);
    }

    /** 가게 정보 상태 저장 했을 경우, 가게 정보 및 isDirty 등의 formState 초기화 */
    if (isSubmitSuccessful) {
      reset(getValues());
    }
  }, [serviceStatus, setValue, isSubmitSuccessful]);

  /** 페이지 이동 시에도 값이 유지될 수 있도록 구현 */
  useEffect(() => {
    reset({
      serviceStatus: items.serviceStatus,
      platforms: [...vanPlatformList, ...deliveryPlatformList],
    });
  }, [location]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StoreInfoBoxWrapper>
        {/** 회수예정일자 수정 모달 */}
        {isVisibleExpectedPaybackDate && depositDate?.lastDepositDate && (
          <EditExpectedPaybackDateModal
            isVisible={isVisibleExpectedPaybackDate}
            onClose={closeExpectedPaybackDateModal}
            lastDepositDate={depositDate.lastDepositDate}
            store={items}
          />
        )}

        <ChangeButton
          type="button"
          onClick={onClickExpandButton}
          renderIcon={isSelectedExpand ? SubtractIcon : AddIcon}
          hasIconOnly
          kind="tertiary"
        />
        {/** 서비스 상태 저장 버튼 */}
        <SaveButton
          type="submit"
          disabled={isDisabled}
          renderIcon={SaveIcon}
          size="sm"
          iconDescription="가게 정보 저장"
          kind="tertiary"
        >
          상태저장
        </SaveButton>

        <StoreInfoContentsWrapper>
          {/** Left | 가게 정보 */}
          <StoreInfoSummaryWrapper>
            <UnderDepositStack>
              {/** 가게 이름 / 사장님 이름 / 사업자 번호 */}
              <Text size={20} bold>
                {`${store} / ${name} / ${businessNumber}`}
              </Text>
              {/** 더보기 버튼 */}
              <MenuButton
                type="button"
                flipped={document?.dir === "rtl"}
                aria-label="overflow-menu"
                iconDescription="설정"
                renderIcon={OverflowMenuIcon}
              >
                <OverflowMenuItem onClick={handleClickExpectedPaybackDate} itemText="회수예정일자 수정" />
              </MenuButton>
            </UnderDepositStack>

            {/** 미수금 정보 */}
            <UnderDepositStack>
              <Text size={20} bold>
                {`미수금: ${changeNumberToMoney(items.underDeposit)} 원`}
              </Text>
              <a href={`${earlypayAdminUrl}/earlypay/underdeposit/?q=${store}`} target="_black">
                <UnderDepositDetailButton type="button" size="sm" kind="tertiary">
                  미수상세
                </UnderDepositDetailButton>
              </a>
            </UnderDepositStack>

            {/** 선정산 시작일 및 계정 정보 */}
            {isSelectedExpand && <UserAccountList items={items} />}
          </StoreInfoSummaryWrapper>

          {/** Right | 연동 카드 및 매출 정보 */}
          <IntegratedSalesWrapper>
            {isSelectedExpand && (
              <SelectWrapper id="select-1" labelText="서비스 상태 정보" {...register(`serviceStatus`)}>
                <SelectItem value="서비스 사용중" text="서비스 사용중" />
                <SelectItem value="해지 진행" text="해지 진행" />
              </SelectWrapper>
            )}

            <ConnectedPlatformList fields={fields} control={control} isCloseService={isCloseService} />
          </IntegratedSalesWrapper>
        </StoreInfoContentsWrapper>
      </StoreInfoBoxWrapper>
    </form>
  );
};

export default StoreInfoBox;
