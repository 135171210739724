import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { get_store_bizppurio } from "src/apis/admin";

import { HeadingCard } from "src/components/block";

import type { GetUserInterface } from "src/types/adminType";
import type { DetailPropsInterface } from "src/pages/Admin/Detail";

const CopyButton = styled.button`
  padding: 6px 12px;
  border-radius: 6px;
  margin-bottom: 10px;
`;

const Bizppurio = ({ _storeId, _date }: DetailPropsInterface) => {
  const [bizppurio, setBizppurio] = useState<string[]>([]);
  const storeId: number = _storeId;
  const date: string = _date;

  const getDetailStore = async (storeId = 0) => {
    const params: GetUserInterface = {
      search: "",
      page: 1,
      page_size: 1000,
    };
    const { results } = await get_store_bizppurio(params, storeId);

    const bizppurioString: string[] = results
      .filter(bizz => {
        const bizzDate = bizz.created.split("T")[0];
        if (bizzDate === date) return bizz;
      })
      .map(bizz => {
        return bizz.contents;
      });

    setBizppurio(bizppurioString);
  };

  const cpoyTextHandler = async (content: string) => {
    try {
      await navigator.clipboard.writeText(content);
      alert("복사되었습니다.");
    } catch {
      alert("복사실패");
    }
  };

  useEffect(() => {
    getDetailStore(storeId);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: true,
  };

  return (
    <HeadingCard _title={`비즈뿌리오 발송 내역 (${bizppurio.length}건)`}>
      <Slider {...settings}>
        {bizppurio.length === 0 && <div>비즈뿌리오 발송내역이 없습니다.</div>}
        {bizppurio.map((content, idx) => {
          return (
            <div key={idx}>
              <CopyButton onClick={async () => cpoyTextHandler(content)}>클립보드 복사</CopyButton>
              <div style={{ height: "400px" }}>{content}</div>
            </div>
          );
        })}
      </Slider>
    </HeadingCard>
  );
};

export default Bizppurio;
