import { useMutation, useQueryClient } from "react-query";
import { instance } from "../instance";

// 특정 날짜에 따른 가게의 입출금 내역을 수정합니다.
const delete_deposit_detail = async (deposit_id: number) => {
  const { data } = await instance({
    method: "delete",
    url: `/v2/admin/deposits/${deposit_id}`,
  });

  return data;
};

export const useDeleteDepositDetail = (deposit_id: number) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ["DeleteDepositDetail", deposit_id],
    (deposit_id: number) => delete_deposit_detail(deposit_id),
    {
      onSuccess: () => {
        console.log("onSuccess");
        queryClient.invalidateQueries(["DepositDetail"]);
        queryClient.invalidateQueries(["DepositOverview"]);
      },
      onError: error => {
        console.log("onError", error);
      },
    },
  );

  return mutation;
};
