import styled from "styled-components";

const HeaderWrap = styled.div`
  margin-top: 100px;
  padding: 12px 100px 0px 100px;

  @media (max-width: 1100px) {
    padding: 12px 30px 0px 30px;
  }

  @media (max-width: 480px) {
    padding: 12px 10px 0px 10px;
  }
`;

const Wrap = styled.div`
  width: 100%;
  background: #fafafa;
  min-height: 100vh;
  padding: 12px 100px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 12px;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    padding: 12px 30px;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    padding: 12px 10px;
  }
`;

const ColumnGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export { HeaderWrap, Wrap, ColumnGrid };
