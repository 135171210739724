import styled from "styled-components";

const Wrap = styled.div`
  width: 100%;
  min-height: 100vh;
  margin-top: 100px;
  padding: 12px 100px 0px 100px;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
    padding: 12px 30px 0px 30px;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    padding: 12px 10px 0px 10px;
  }
`;
export { Wrap };
