// style
import styled, { css } from "styled-components";
import theme from "src/styles/theme";

export const buttonStyle = css`
  position: relative;
  overflow: hidden;
  transition: all 0.15s;
  color: #fff;
  outline: 0;
  border-radius: 3px;
  width: auto;
  border: none;
  background-color: ${theme.color.blue_10};
  padding: 14px 22px;
  box-sizing: border-box;

  &:active {
    filter: brightness(0.9);
  }

  span.ripple {
    top: 0;
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 550ms linear;
    background-color: rgba(0, 0, 0, 0.03);
  }

  @keyframes ripple {
    to {
      transform: scale(2);
      opacity: 0;
    }
  }
`;

export const DefaultButtonStyle = styled.button`
  ${buttonStyle}
`;

export const FullButtonStyle = styled.button`
  ${buttonStyle}
  width:100%;
`;

export const SmallButtonStyle = styled.button`
  ${buttonStyle}
  padding: 10px 16px;
  /* max-height: 40px; */
`;
