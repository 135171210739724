import React from "react";

import {
  UserChartBox,
  EarlypayBox,
  YearDepositBox,
  MonthDepositBox,
  DayDepositBox,
  ItemChartBox,
} from "src/components/module/home/index";
import { HeaderWrap, Wrap, ColumnGrid } from "./index.styled";

const Home = () => {
  return (
    <>
      <HeaderWrap>
        <EarlypayBox />
      </HeaderWrap>
      <HeaderWrap style={{ margin: 0 }}>
        <ItemChartBox />
      </HeaderWrap>
      <Wrap>
        <ColumnGrid>
          <UserChartBox />
        </ColumnGrid>
        <ColumnGrid>
          <DayDepositBox />
        </ColumnGrid>
        <ColumnGrid>
          <MonthDepositBox />
        </ColumnGrid>
        <ColumnGrid>
          <YearDepositBox />
        </ColumnGrid>
      </Wrap>
    </>
  );
};

export default Home;
